import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// const lin = "https://dpp.baavlibuch.com"
// const lin = "http://localhost:8000"

const axiosInstance = axios.create({
    baseURL: "https://dpp.baavlibuch.com"
    // baseURL: "http://localhost:8000"
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
            const isTokenExpired = true;

            if (isTokenExpired) {
                localStorage.clear();
                alert("jwt token expire")
                const navigate = useNavigate();
                navigate('/login');
            } else {
                console.log("error")
                alert(error)
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;