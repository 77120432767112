import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import axiosInstance from "./axiosInterceptor";
import Template from "./Template";
import { MdDelete } from "react-icons/md";
import ConvertApi from 'convertapi-js'
import { MdEdit } from "react-icons/md";
import Qrimage from './assets/Qr.png'
import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
import Receptionistregistration from "./Component/Receptionistregistration";
import PatientFiles from "./Component/patientfiles";
import DragResizeExample from "./Component/Typing";
import Share from "./Component/Share";
import PageQR from './Component/PageQR';

const PDFJS = require("pdfjs-dist/webpack");


function Doctorform({ setqrUrl, qrUrl, setFormData, forms, formData, setIsMounted, isMounted, setCheckedTemplates, checkedTemplates, pageNames, setpageNames, setaudio, buttonRef, stamp, setstamp, url_id, seturl_id, uuid, setUuid, qrCodeUrl, setQrCodeUrl }) {


  const accessToken = localStorage.getItem('token');

  const { t, i18n } = useTranslation();

  const [templateOn, setTemplateOn] = useState(false);
  const [stampOn, setstampOn] = useState(false);
  const [qron, setqron] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorqr, setdoctorqr] = useState("")
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [selectedStampIndex, setSelectedStampIndex] = useState(0);
  const [showCrossIndex, setShowCrossIndex] = useState(null);
  const [isMultipleSelectActive, setIsMultipleSelectActive] = useState(false); // New state variable
  const [MultipleSelectActive, setMultipleSelectActive] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pdfurl, setpdfurl] = useState('')
  const [visible, setvisible] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [drawingMode, setDrawingMode] = useState(true);
  const [isErasing, setIsErasing] = useState(false);
  let [currentIndex, setCurrentIndex] = useState(0)


  const [storedTemplate, setstoredTemplate] = useState([
    { id: "123", url: "/Template3.jpg", name: "Template 2" },
  ]);

  const [storedstamp, setstoredstamp] = useState([]);


  function generateQRCode(dataUrl) {
    const encodedUrl = encodeURIComponent(dataUrl);
    return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodedUrl}`;
  }

  useEffect(() => {
    const Url = `https://dpp.baavlibuch.com/pdf/${localStorage.getItem("user_login")}`
    const qrCodeImageUrl = generateQRCode(Url);
    setdoctorqr(qrCodeImageUrl)
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cUrl = await getCurrentUrlFromDatabase();
        console.log(cUrl);
        // Map each object in the array to the desired format
        const newTemplates = cUrl.map(template => ({
          id: template._id,
          url: template.url,
          name: template?.name || "" // Assuming 'name' property should be set to "man" for each template
        }));
        // Update storedTemplate state with the mapped array
        setstoredTemplate((prevTemplates) => [...newTemplates]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/form/getstamp`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        })
        const cUrl = response.data
        const newTemplates = cUrl.map(template => ({
          id: template._id,
          url: template.url,
          name: template?.name || "" // Assuming 'name' property should be set to "man" for each template
        }));
        // Update storedTemplate state with the mapped array
        setstoredstamp(newTemplates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);



  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1024px) and (min-width: 360px)');
    const handleMediaQueryChange = (event) => {
      setIsTablet(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const ChangeStatefunc = async () => {
    try {
      const id = forms[currentIndex]._id;
      const state = "current";
      const docId = forms[currentIndex].doctor_ID;
      if (forms?.filter(form => form.status == 'current')?.length < 1) {
        const response = await axiosInstance.put('/api/form/changeState', {
          id: id,
          state: state,
          doctor_ID: docId
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const [formId, setFormId] = useState()
  useEffect(() => {
    console.log("all Form", forms)
    // let currentIndex = parseInt(localStorage.getItem("currentIndex"), 10);
    console.log("CI", currentIndex)
    while (forms[currentIndex] &&
      forms[currentIndex].status !== "new" &&
      forms[currentIndex].status !== "current" &&
      currentIndex < forms.length) {
      currentIndex++;
    }
    setCurrentIndex(currentIndex)
    let nextFormData;
    // if (currentIndex)
    //   nextFormData = forms[currentIndex];
    // else
    //   nextFormData = forms[0];
    nextFormData = forms[currentIndex];
    setFormData(nextFormData);
    ChangeStatefunc()
  }, [forms, formData]);

  const handleSubmit = async (e) => {

    setqron(true);
    if (buttonRef.current) {
      buttonRef.current.click(); // Programmatically click the button
    }
    e.preventDefault();

    try {
      if (window.handleSaveToPdf) {
        await window.handleSaveToPdf(); // Wait for handleSaveToPdf to finish
        seturl_id(" "); // Set the new url_id after successful resolution
        setUuid(" ");
      }
    } catch (error) {
      console.error('Error during handleSaveToPdf:', error);
    }
    console.log(forms)
  };
  console.log("doctor", forms)

  const setNextFormData = async (isTrue) => {

    // Retrieve and validate currentIndex from localStorage
    // const currentIndex = parseInt(localStorage.getItem("currentIndex"), 10);
    console.log("CI", currentIndex)
    if (isNaN(currentIndex) || currentIndex < 0) {
      console.error('Invalid currentIndex');
      return;
    }

    // Ensure forms is defined and check index bounds
    if (!Array.isArray(forms) || currentIndex >= forms.length) {
      console.error('Forms array is invalid or currentIndex is out of bounds');
      return;
    }
    setqron(false)
    // Ensure there is a next form to process

    forms[currentIndex].status = "consulted";
    const id = forms[currentIndex]._id;
    setFormId(id);
    let state;
    console.log(isTrue)
    if (isTrue) {
      state = "consulted";
    }
    else {
      state = "new";
    }
    const docId = forms[currentIndex].doctor_ID;
    console.log(id)
    try {

      const response = await axiosInstance.put('/api/form/changeState', {
        id: id,
        state: state,
        doctor_ID: docId
      });
      console.log("Response Data:", response.data);


      // UI updates after successful API call
      setaudio(prevState => prevState + 1);
      setqron(false);
      setCheckedTemplates([]);
      setpageNames([]);
      // Move to next form and update localStorage
      const nextFormData = forms[currentIndex + 1];
      setFormData(nextFormData);
      if (currentIndex < forms.length - 1) {
        setCurrentIndex(prev => prev + 1);
      } else {
        console.log('No more forms to process');
      }
      // localStorage.setItem("currentIndex", currentIndex + 1); // Update index

    } catch (error) {
      console.error('Error updating form state:', error);
    }
  };


  const setPrevFormData = () => {
    // setaudio(prevState => prevState - 1);
    // setqron(false);
    // setCheckedTemplates([]);
    // setpageNames([]);

    // let currentIndex = parseInt(localStorage.getItem("currentIndex"), 10); // Convert to integer
    // if (currentIndex > 0) { // Check that we are not at the first element
    //   const prevFormData = forms[currentIndex - 1];
    //   setFormData(prevFormData);
    //   localStorage.setItem("currentIndex", currentIndex - 1); // Update index
    // }
  };


  // console.log("DOCTOR", formData)
  // const deleteData = async () => {
  //   try {
  //     console.log(formData.bio.id);

  //     const response = await axios.delete(
  //       `${lin}/api/form/delete`,
  //       {
  //         data: { id: formData.bio.id }, // Sending data in the request body
  //       }
  //     );

  //     // console.log(response.data); // Logging the response data
  //   } catch (error) {
  //     console.error("Error deleting data:", error);
  //   }
  // };

  // const prevpatient = (e) => {
  //   e.preventDefault();
  //   const currentIndex = forms.indexOf(formData);

  //   if (currentIndex == 1)
  //     alert("This is the last patient")

  //   if (currentIndex > 0) {
  //     const prevFormData = forms[currentIndex - 1];
  //     setFormData(prevFormData);
  //   } else {
  //     alert("No Patients Left !!!!");
  //   }
  // };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'bxva8g23'); // Replace with your upload preset

    const response = await fetch('https://api.cloudinary.com/v1_1/drz2uk3vm/image/upload', {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data.secure_url;
  };

  const uploadFileToBackend = async (file, filename) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    try {
      const response = await axiosInstance.post('/api/form/uploadfiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.url;
    } catch (error) {
      console.error('Failed to upload file', error);
      throw new Error('Failed to upload file');
    }
  };
  // Function to save URL to your database
  const saveToDatabase = async ({ url, name }) => {
    try {

      const response = await axiosInstance.post(`/api/form/sendtemplate`, {
        url,
        name
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      return response.data;
      // Response handling (e.g., update data, display success message)
    } catch (error) {
      console.error('Error saving URL to database:', error);
    }
  };

  const saveStampToDatabase = async ({ url, name }) => {
    try {

      const response = await axiosInstance.post(`/api/form/sendstamp`, {
        url,
        name
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      return response.data;
      // Response handling (e.g., update data, display success message)
    } catch (error) {
      console.error('Error saving URL to database:', error);
    }
  };

  // Function to get currently saved URL from database
  const getCurrentUrlFromDatabase = async () => {
    try {
      const response = await axiosInstance.get(`/api/form/gettemplate`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error('Error getting current URL from database:', error);
      return null;
    }
  };


  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  // Convert PDF to an array of images (one image per page)
  const convertPdfToImages = async (file) => {
    const images = [];

    try {
      // Ensure you have a function to read file data into ArrayBuffer or Uint8Array
      const data = await readFileData(file);

      // Load the PDF document
      const pdf = await PDFJS.getDocument(data).promise;

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render the page into the canvas
        await page.render({ canvasContext: context, viewport: viewport }).promise;

        // Convert canvas to image data URL
        images.push(canvas.toDataURL("image/jpeg")); // You can specify format here if needed (e.g., "image/png")

        // Clean up canvas
        canvas.remove();
      }
    } catch (error) {
      console.error('Error converting PDF to images:', error);
    }

    return images;
  };
  const convertDocxToImages = async (file) => {
    if (file) {
      try {
        // Convert DOCX to JPG using ConvertAPI
        const formData = new FormData();
        formData.append('file', file);

        const convertResponse = await axios.post(
          'https://v2.convertapi.com/convert/doc/to/jpg?Secret=secret_RluxDXnIKxFZcE9W',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        const files = convertResponse?.data?.Files || [];
        const imageUrlsTemp = [];

        for (const fileData of files) {
          // Convert base64 image data to Blob
          const blob = new Blob([new Uint8Array(atob(fileData.FileData).split('').map(char => char.charCodeAt(0)))], { type: 'image/jpeg' });
          // const jpgUrl = URL.createObjectURL(blob);
          imageUrlsTemp.push(blob);
        }


        return imageUrlsTemp;
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const handleItChange = async (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    console.log("this is ", selectedFile);
    if (selectedFile) {
      try {
        const fileType = selectedFile.type;
        if (fileType === 'application/pdf') {
          console.log("Processing PDF...");

          const images = await convertPdfToImages(selectedFile);
          console.log(images)

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);

            // Save each Cloudinary URL to the database
            const savedTemplate = await saveToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` });

            return { id: savedTemplate._id, url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));

          // Update stored templates state with the newly saved templates
          setstoredTemplate((prevTemplates) => [...prevTemplates, ...uploadedImages]);

        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {
          // Handle image file (JPEG or PNG)

          const cloudinaryUrl = await uploadFileToBackend(selectedFile, selectedFile.name);

          console.log('Uploaded image to Cloudinary:', cloudinaryUrl);

          const savedTemplate = await saveToDatabase({ url: cloudinaryUrl, name: selectedFile.name });

          // Update stored templates state with the newly saved template
          setstoredTemplate((prevTemplates) => {
            const newTemplate = { id: savedTemplate._id, url: cloudinaryUrl, name: selectedFile.name };
            return [...prevTemplates, newTemplate];
          });
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          console.log("Processing DOCX...");

          const images = await convertDocxToImages(selectedFile);
          console.log('Converted DOCX to images:', images);

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);

            // Save each Cloudinary URL to the database
            const savedTemplate = await saveToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` });

            return { id: savedTemplate._id, url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));

          // Update stored templates state with the newly saved templates
          setstoredTemplate((prevTemplates) => [...prevTemplates, ...uploadedImages]);

        }
        else {
          console.error('Unsupported file type');
        }
      } catch (error) {
        console.error('Error handling file:', error);
      }
    }
  };


  const handleAddPage = async (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    console.log("this is ", selectedFile);
    if (selectedFile) {
      try {
        const fileType = selectedFile.type;
        if (selectedFile.type === 'application/pdf') {
          console.log("hurray ");

          const images = await convertPdfToImages(selectedFile);
          console.log('Converted PDF to images:', images);

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);
            return { id: "012", url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));
          console.log(uploadedImages)

          setCheckedTemplates(prevTemplates => {
            const newTemplates = uploadedImages.map((image, idx) => ({
              index: prevTemplates.length + idx, // Calculate the index based on current state length
              template: image
            }));
            return [...prevTemplates, ...newTemplates];
          });

          setpageNames(prevTemplates => {
            const newPageNames = uploadedImages.map(image => image.name);
            return [...prevTemplates, ...newPageNames];
          });

        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {
          const cloudinaryUrl = await uploadFileToBackend(selectedFile, selectedFile.name);
          console.log('Uploaded image to Cloudinary:', cloudinaryUrl);

          setCheckedTemplates(prevTemplates => {
            return [...prevTemplates, { index: 0, template: { id: "0123", url: cloudinaryUrl, name: selectedFile.name } }];
          });

          setpageNames(prevTemplates => {
            return [...prevTemplates, ...selectedFile.name];
          });

        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          console.log("Processing DOCX...");

          const images = await convertDocxToImages(selectedFile);
          console.log('Converted DOCX to images:', images);

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);

            return { id: "012", url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));

          setCheckedTemplates(prevTemplates => {
            const newTemplates = uploadedImages.map((image, idx) => ({
              index: prevTemplates.length + idx, // Calculate the index based on current state length
              template: image
            }));
            return [...prevTemplates, ...newTemplates];
          });

          setpageNames(prevTemplates => {
            const newPageNames = uploadedImages.map(image => image.name);
            return [...prevTemplates, ...newPageNames];
          });

        } else {
          console.error('Unsupported file type');
        }
      } catch (error) {
        console.error('Error handling file:', error);
      }
    }
  };


  const handleAddstamp = async (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    console.log("this is ", selectedFile);
    if (selectedFile) {
      try {
        const fileType = selectedFile.type;
        if (selectedFile.type === 'application/pdf') {
          console.log("hurray ");

          const images = await convertPdfToImages(selectedFile);
          console.log('Converted PDF to images:', images);

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            const savedstamp = await saveStampToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` });
            return { id: savedstamp._id, url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));
          console.log(uploadedImages)

          setstoredstamp((prevTemplates) => [...prevTemplates, ...uploadedImages]);
          // setstamp(prevTemplates => {
          //   const newTemplates = uploadedImages.map((image, idx) => ({
          //     index: prevTemplates.length + idx, // Calculate the index based on current state length
          //     stamp: image
          //   }));
          //   return [...newTemplates];
          // });

        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {

          const cloudinaryUrl = await uploadFileToBackend(selectedFile, selectedFile.name);
          const savedstamp = await saveStampToDatabase({ url: cloudinaryUrl, name: selectedFile.name });
          setstoredstamp((prevTemplates) => {
            const newTemplate = { id: savedstamp._id, url: cloudinaryUrl, name: selectedFile.name };
            return [...prevTemplates, newTemplate];
          });

          // setstamp(prevTemplates => {
          //   return [{ index: 0, stamp: { id: "0123", url: cloudinaryUrl, name: selectedFile.name } }];
          // });

        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          console.log("Processing DOCX...");

          const images = await convertDocxToImages(selectedFile);
          console.log('Converted DOCX to images:', images);

          const uploadedImages = await Promise.all(images.map(async (image, index) => {
            const cloudinaryUrl = await uploadFileToBackend(image, `${selectedFile.name}-${index + 1}`);
            console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);

            // Save each Cloudinary URL to the database
            const savedstamp = await saveStampToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` });

            return { id: savedstamp._id, url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
          }));

          // setstamp(prevTemplates => {
          //   const newTemplates = uploadedImages.map((image, idx) => ({
          //     index: prevTemplates.length + idx, // Calculate the index based on current state length
          //     stamp: image
          //   }));
          //   return [...newTemplates];
          // });
          setstoredstamp((prevTemplates) => [...prevTemplates, ...uploadedImages]);

        } else {
          console.error('Unsupported file type');
        }
      } catch (error) {
        console.error('Error handling file:', error);
      }
    }
  };

  const handleEdit = async (templateId, index) => {
    const newName = prompt("Please enter a new name for the template:");

    if (newName !== null) {
      try {
        const response = await axiosInstance.put(`/api/form/templates/${templateId}`, {
          name: newName
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        console.log(response.data)
        const updatedName = response.data?.name;

        // Update the template name in the storedTemplate state
        setstoredTemplate(prevTemplates => {
          const updatedTemplates = [...prevTemplates];
          updatedTemplates[index].name = updatedName;
          return updatedTemplates;
        });

        // Update isPromptActive status
        setIsPromptActive(prevIsPromptActive => {
          const newIsPromptActive = [...prevIsPromptActive];
          newIsPromptActive[index] = true; // Deactivate prompt for the specific index
          return newIsPromptActive;
        });
      } catch (error) {
        console.error('Error updating template name:', error);
        // Handle error (e.g., display user-friendly message, retry logic)
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/form/deletetemplate/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setstoredTemplate(prevTemplates => prevTemplates.filter(template => template.id !== id));
    } catch (error) {
      console.error('Error deleting template from backend:', error.message);
    }
  };

  const handlestampDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/form/deletestamp/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setstoredstamp(prevTemplates => prevTemplates.filter(template => template.id !== id));
    } catch (error) {
      console.error('Error deleting template from backend:', error.message);
    }
  };

  const handleTemplateClick = (index) => {
    setSelectedTemplateIndex(index);
    // Add the clicked template to checkedTemplates
    if (!MultipleSelectActive) {
      setCheckedTemplates(prevTemplates => {
        return [...prevTemplates, { index, template: storedTemplate[index] }];
      });
      setpageNames(prevTemplates => {
        return [...prevTemplates, storedTemplate[index]?.name];
      });
    }
  };

  const handleStampClick = (index) => {
    if (checkedTemplates.length > 0) {
      setSelectedStampIndex(index);
      setIsModalOpen(true);
    }

    // if (checkedTemplates.length > 0) {
    //   setstamp(prevTemplates => {
    //     // Append the clicked template to the state
    //     return [...prevTemplates, { index: checkedTemplates?.length - 1 || 0, stamp: storedstamp[index] }];
    //   });
    // }
    // Additional logic if needed
  };

  const bind = useLongPress(() => {
    setMultipleSelectActive(prevState => !prevState);
    setIsTemplateSelected(prevState => !prevState);
    setIsEditSelected(prevState => !prevState);
    setCheckedTemplates(prevTemplates => {
      const lastIndex = prevTemplates.length - 1;
      if (lastIndex >= 1) {
        return prevTemplates.slice(0, lastIndex);
      } else {
        return [];
      }
    });
  });

  const handleInputChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmited = (event) => {
    event.preventDefault();
    const whatsappURL = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(pdfurl)}`;
    window.open(whatsappURL, '_blank');

  };

  const [isPromptActive, setIsPromptActive] = useState(Array.from({ length: storedTemplate?.length }, () => false));
  const containerRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState('');





  const [isOpen, setIsOpen] = useState(false);

  const [prevPatient, setPreviousPatient] = useState([])

  const fetchPrevPatient = async (e) => {
    console.log(forms)
    console.log("current", currentIndex)
    if (currentIndex >= forms?.length) {
      currentIndex--;
    }
    const docId = forms[currentIndex]?.doctor_ID;
    console.log("id", docId);

    try {
      const response = await axiosInstance.get('/api/form/fetchPrevPatient', {
        params: { id: docId }, // Pass `id` as a query parameter
      });

      console.log(response.data); // Access the response data directly
      setPreviousPatient(response.data); // Set the response data to state

    } catch (error) {
      console.error('Error fetching', error);
    }
  };

  const toggleSidebar = async (e) => {
    e.preventDefault();
    await fetchPrevPatient();
    setIsOpen(!isOpen);
  };
  const handleAddToQueue = async (card) => {
    console.log(currentIndex)
    const docId = forms[currentIndex]?.doctor_ID;
    const data = {
      id: card._id,
      state: "new",
      doctor_ID: docId
    }
    const response = await axiosInstance.put('/api/form/changeState', data);
    setIsOpen(false)
    if (response.status === 200) {
      window.location.reload();
    }
  }
  const [newPatient, setNewPatient] = useState(false);
  const AddNewPatient = () => {
    setNewPatient(true)
  }

  // if (localStorage.getItem("reload") === true) {
  //   // setCheckedTemplates(prevPageNames => {
  //   //   return [...prevPageNames]; // Collect page names for each URL
  //   // })
  //   alert("done")

  //   localStorage.setItem("reload", false)
  // }

  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsContentVisible(true);
    }, 1000); // Delay of 1 second
    return () => clearTimeout(timer);
  }, []);


  localStorage.setItem("uuid", uuid)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedPages, setSelectedPages] = useState([]);

  const handleCheckboxChange = (page) => {
    setSelectedPages((prevSelected) =>
      prevSelected.includes(page)
        ? prevSelected.filter((p) => p !== page) // Remove page if already selected
        : [...prevSelected, page] // Add page if not selected
    );
  };

  const handleSubmitpage = (page) => {
    if (checkedTemplates.length > 0) {
      setstamp(prevTemplates => {
        // Append the clicked template to the state
        return [...prevTemplates, { index: checkedTemplates?.length - 1 || 0, pageNumber: page, stamp: storedstamp[selectedStampIndex] }];
      });
    }
    setIsModalOpen(false);
  };


  return (

    <div className="App border " style={{ marginTop: "-35px" }}>
      <Share formId={forms[currentIndex]} setNextFormData={setNextFormData} />
      <div className="relative ">
        <PageQR url_id={url_id} setQrCodeUrl={setQrCodeUrl} setUuid={setUuid} uuid={uuid} formdata={formData} />
        <div
          className={`fixed top-0 right-0 h-full bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
            } transition-transform duration-300 ease-in-out w-64 z-10`}
        >
          <div className="p-4 flex justify-between items-center">
            <h2 className="text-xl font-bold">Previous Patients</h2>
            <button onClick={toggleSidebar} className="text-gray-500">
              ✕
            </button>
          </div>

          <div className="p-4 space-y-4 h-[90%] overflow-scroll">
            {prevPatient.slice().reverse().map((card) => (
              <div
                key={card.id}
                className="p-4 bg-gray-100 rounded-lg shadow-sm flex items-center justify-between"
              >
                <div>
                  <p className="font-semibold">{card.bio.name}</p>
                  <p className="text-gray-500">order: {card.order}</p>
                </div>
                <button
                  className="bg-blue-500 text-white text-sm px-2 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors"
                  onClick={() => handleAddToQueue(card)}
                >
                  Add to Queue
                </button>
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={toggleSidebar}
          ></div>
        )}
      </div>
      {newPatient && <div className=" border relative  ">
        <Receptionistregistration />
      </div>
      }
      <PatientFiles patient_id={formData} setCheckedTemplates={setCheckedTemplates} setpageNames={setpageNames} seturl_id={seturl_id} />
      <form id="form" className={`form   w-full`}>
        <div className="bio-tab font-bold ml-2  text-xl border"></div>
        <div ref={containerRef} style={{ margin: "0px" }} >
          <div className="bio-content  py-2  border">
            <span className="bio-tab pl-1 pr-2 text-md "> {t('doctorform.name')}: {formData?.bio?.name}</span>
            <span className="bio-tab pr-2 text-md ">{t('doctorform.age')}: {formData?.bio?.age}</span>
            {/* <div className="bio-tab  text-md">ID : {formData?.bio?.id}</div> */}
            <span className="bio-tab pr-2  text-md">{t('doctorform.sex')}: {formData?.bio?.sex}</span>
          </div>

          {/* <DragResizeExample /> */}
          {isContentVisible && (
            !isMultipleSelectActive ? (
              <div >
                {checkedTemplates.map((template, index) => (
                  <div >
                    <Template template={template?.template} dimensions={canvasDimensions} setqrUrl={setqrUrl} setpdfurl={setpdfurl} containerRef={containerRef} isMounted={isMounted} setIsMounted={setIsMounted} isLoading={isLoading} setIsLoading={setIsLoading} formData={formData} stamp={stamp} setstamp={setstamp} index={index ? index : 0} length={checkedTemplates?.length}
                      isErasing={isErasing} setIsErasing={setIsErasing} drawingMode={drawingMode} setDrawingMode={setDrawingMode} pageNames={pageNames} url_id={url_id}
                      seturl_id={seturl_id} uuid={uuid} qrCodeUrl={qrCodeUrl} />
                  </div>
                ))
                }

              </div>
            ) : (<Template template={storedTemplate[selectedTemplateIndex]} dimensions={canvasDimensions} setqrUrl={setqrUrl} setpdfurl={setpdfurl} isLoading={isLoading} setIsLoading={setIsLoading} formData={formData} stamp={stamp} isErasing={isErasing} setIsErasing={setIsErasing} drawingMode={drawingMode} setDrawingMode={setDrawingMode} url_id={url_id}
              seturl_id={seturl_id} />))}
        </div>
        {qron && (
          <div className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-10 flex justify-center items-center">
            <div className="flex w-full h-[80%] justify-center items-center">
              <div className="bg-white w-[70%] h-[90%] z-20 rounded-lg relative overflow-y-auto overflow-x-hidden flex flex-col items-center p-8">
                <div className="absolute top-4 right-4 cursor-pointer" onClick={() => setqron(false)}>
                  <img className="h-10 w-10" src="/cross2.png" alt="Close" />
                </div>
                <div className="flex flex-col items-center justify-center flex-grow">
                  {/* {isLoading ? ( // Render spinner if loading
                    <img src={icons8spinner} alt="Loading..." className="h-20 w-20" />
                  ) : qrUrl ? (
                    <img
                      src={Qrimage}
                      alt="QR Code"
                      className="h-40 w-40 my-4"
                    />
                  ) : (
                    <img
                      src={doctorqr}
                      alt="Doctor QR"
                      className="h-40 w-40 my-4"
                    />
                  )} */}
                  <img
                    src={doctorqr}
                    alt="QR Code"
                    className="h-40 w-40 my-4"
                  />
                  <div className="mt-6 w-full px-8">
                    <label className="block mb-2">
                      {t('register.phone')}:
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={handleInputChange}
                        className="ml-1 border p-1 rounded w-full"
                      />
                    </label>
                    <button onClick={handleSubmited} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2 w-full">
                      {t('share_via_whatsapp')}
                    </button>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between mt-3">
                    <button className="text-md font-bold bg-blue-500 text-white rounded-md w-full md:w-[150px] hover:bg-blue-700 m-2 md:mb-0 h-12 md:h-16" onClick={() => { setqron(false); setCheckedTemplates([]); setaudio(prevState => prevState + 1); setstamp([]); setFormData([]); setpageNames([]); AddNewPatient(); }}>
                      {t('new')}
                    </button>
                    <button className="text-md font-bold bg-blue-500 text-white rounded-md w-full md:w-[150px] hover:bg-blue-700 m-2 md:mb-0 h-12 md:h-16" onClick={() => { setNextFormData(true) }}>
                      {t('next_patient')}({Math.max(
                        (forms?.filter(form => form.status === 'new').length +
                          forms?.filter(form => form.status === 'current').length - 1) || 0,
                        0
                      )})
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}



        {
          templateOn && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center">
              <div className="w-[80vw] h-[80vh] bg-white rounded-lg p-10 overflow-y-auto overflow-x-hidden relative">
                <div className="absolute top-4 right-0 cursor-pointer m-2 mt-0" onClick={() => {
                  setTemplateOn(false);
                  window.scrollTo({
                    top: document.body.scrollHeight - 800, // Scroll to the bottom of the page
                    behavior: 'smooth' // Change to 'auto' for instant scrolling
                  });

                }}>
                  <img className="h-10 w-10" src="/cross2.png" alt="X" />
                </div>
                <div className="mb-4 mt-4">
                  <input
                    type="text"
                    placeholder="Search by template name"
                    className="border p-2 w-full rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setSearchQuery(e.target.value)} // This will trigger the search
                  />
                </div>
                <div className="grid justify-center sm:justify-normal flex-wrap sm:flex flex-row gap-12">
                  {storedTemplate?.filter((template) =>
                    template.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )?.map((template, index) => (

                    <div
                      className={`relative h-[200px] w-[150px] flex justify-center items-center border ${selectedTemplateIndex === index ? 'border-black' : ''}`}
                      key={index}
                      onClick={() => handleTemplateClick(index)}
                      {...bind()}
                    >

                      <div className="absolute  top-44" >
                        <span>{template.name}</span>
                      </div>

                      <img className="h-[180px] w-[180px] cursor-pointer" src={template.url} alt="" />
                      <div className="flex">
                        {selectedTemplateIndex === index && isTemplateSelected && (
                          <div className="absolute bottom-[168px] left-[118px] cursor-pointer m-2" onClick={() => handleDelete(template.id)}>
                            <MdDelete className="h-10 w-10" />
                          </div>
                        )}
                        {selectedTemplateIndex === index && isEditSelected && (
                          <div className="absolute bottom-[168px] left-[-20px] cursor-pointer m-2" onClick={() => handleEdit(template.id, index)}>
                            <MdEdit className="h-10 w-10" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}


                </div>
                <div className="absolute  left-2">
                  <label htmlFor="fileInput" className="cursor-pointer flex justify-center items-center">
                    {/* <img src="/add1.png" alt="Plus Sign" className="cursor-pointer" /> */}
                    <div
                      className="bg-blue-500 hover:bg-blue-700 text-white flex items-center px-4 py-2  rounded md:h-14 mb-8 mt-16"
                    >
                      {t('doctorform.add_template')}
                    </div>
                  </label>
                  <input type="file" id="fileInput" className="hidden" onChange={handleItChange} />
                </div>
                <div className="absolute right-0">

                  <label htmlFor="addpage" className="cursor-pointer flex justify-center items-center">
                    <div
                      className="bg-blue-500 hover:bg-blue-700 text-white flex items-center px-4 py-2  rounded md:h-14 mb-8 mt-16"
                    >
                      {t('doctorform.add_page')}
                    </div>
                  </label>

                  <input type="file" id="addpage" className="hidden" onChange={handleAddPage} />
                </div>

                {/* <div className="absolute  left-2">

                  <label htmlFor="addstamp" className="cursor-pointer flex justify-center items-center">
                    <div
                      className="bg-blue-500 hover:bg-blue-700 text-white flex items-center px-4 py-2  rounded md:h-14 mb-8 mt-16"
                    >
                      Add Stamp
                    </div>
                  </label>

                  <input type="file" id="addstamp" className="hidden" onChange={handleAddstamp} />
                </div> */}
                {/* {visible && (
                  <>
                    <div id="videoContainer" className="relative">
                     

              </div>
              {visible && (<button className="absolute bottom-2 left-[50%] transform -translate-x-1/2 px-4 py-2 bg-blue-500 text-white rounded  md:h-14" onClick={handleCapture}>
                Take it
              </button>)}
            </>
          )} */}
              </div>
            </div >
          )
        }


        {
          stampOn && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center">
              <div className="w-[80vw] h-[80vh] bg-white rounded-lg p-10 overflow-y-auto overflow-x-hidden relative">
                <div className="absolute top-4 right-0 cursor-pointer m-2 mt-0" onClick={() => {
                  setstampOn(false);
                  window.scrollTo({
                    top: 40, // Adjust this value as needed
                    behavior: 'smooth' // Change to 'auto' for instant scrolling
                  });
                }}>
                  <img className="h-10 w-10" src="/cross2.png" alt="X" />
                </div>
                <div className="grid justify-center sm:justify-normal flex-wrap sm:flex flex-row gap-12">
                  {storedstamp.map((template, index) => (

                    <div
                      className={`relative h-[200px] w-[150px] flex justify-center items-center border ${selectedStampIndex === index ? 'border-black' : ''}`}
                      key={index}
                      onClick={() => handleStampClick(index)}
                      {...bind()}
                    >

                      <div className="absolute  top-44" >
                        <span>{template.name}</span>
                      </div>

                      <img className="h-[180px] w-[180px] cursor-pointer" src={template.url} alt="" />
                      <div className="flex">
                        {selectedStampIndex === index && isTemplateSelected && (
                          <div className="absolute bottom-[168px] left-[118px] cursor-pointer m-2" onClick={() => handlestampDelete(template.id)}>
                            <MdDelete className="h-10 w-10" />
                          </div>
                        )}
                        {selectedStampIndex === index && isEditSelected && (
                          <div className="absolute bottom-[168px] left-[-20px] cursor-pointer m-2" onClick={() => handleEdit(template.id, index)}>
                            <MdEdit className="h-10 w-10" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}


                </div>
                <div className="absolute left-2  ">

                  <label htmlFor="addstamp" className="cursor-pointer flex justify-center items-center">
                    <div
                      className="bg-blue-500 hover:bg-blue-700 text-white flex items-center px-4 py-2  rounded md:h-14 mb-8 mt-16"
                    >
                      Add Stamp
                    </div>
                  </label>

                  <input type="file" id="addstamp" className="hidden" onChange={handleAddstamp} />
                </div>
              </div>
            </div >
          )
        }

        {isModalOpen ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg">
              <h2 className="text-lg font-bold">Select Page Number</h2>
              <div className="flex flex-col">
                {Array.from({ length: checkedTemplates?.length }, (_, i) => (
                  <label key={i}>
                    <input
                      type="radio"
                      name="pageNumber"
                      value={i + 1}
                      onChange={() => handleSubmitpage(i)} // Automatically submit on change
                    />
                    Page {i + 1}
                  </label>
                ))}
              </div>
              {/* <div className="flex justify-end mt-4">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>*/}
            </div>
          </div>
        ) : null}




        <div className="fixed bottom-0 left-0 right-0 m-0 ">
          <div className="flex justify-between items-center max-w-full m-1">
            <button className="text-2xl md:text-4xl font-bold bg-blue-500 text-white rounded-l-lg md:rounded-lg flex-grow-0 flex-shrink w-[25%] max-w-[200px] min-w-[100px] hover:bg-blue-700  md:mb-0 h-12 md:h-16" onClick={toggleSidebar}>
              ←
            </button>

            <div id="Tempbtn" className="md:text-lg font-bold bg-blue-500 text-white md:rounded-lg flex-grow-0 flex-shrink w-[25%] max-w-[200px] min-w-[100px] hover:bg-blue-700  md:mb-0 h-12 md:h-16 flex justify-center items-center cursor-pointer" onClick={() => setTemplateOn(true)}>
              {t('doctorform.page')}
            </div>

            <div id="stampbtn" className="md:text-lg font-bold bg-blue-500 text-white md:rounded-lg flex-grow-0 flex-shrink w-[25%] max-w-[200px] min-w-[100px] hover:bg-blue-700  md:mb-0 h-12 md:h-16 flex justify-center items-center cursor-pointer" onClick={() => setstampOn(true)}>
              {t('doctorform.add_stamp')}
            </div>

            <button className="text-2xl md:text-4xl font-bold bg-blue-500 text-white rounded-r-lg md:rounded-lg flex-grow-0 flex-shrink w-[25%] max-w-[200px] min-w-[100px] hover:bg-blue-700  md:mb-0 h-12 md:h-16" onClick={handleSubmit}>
              →
            </button>
          </div>
        </div>



      </form >

    </div >
  );
}



export default Doctorform;
