import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is imported

const Scanner = () => {
  const videoRef = useRef(null);
  const [scanResult, setScanResult] = useState('');
  const [cameraFacing, setCameraFacing] = useState('environment'); // Default camera
  const qrScannerRef = useRef(null); // Store qrScanner instance

  useEffect(() => {
    const startScanner = async () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.stop(); // Stop previous scanner
      }

      const qrScanner = new QrScanner(
        videoRef.current,
        (result) => {
          const qrResult = typeof result === 'string' ? result : result.data || '';
          setScanResult(qrResult);
          console.log(qrResult);
          window.location.href = qrResult;
        },
        {
          onDecodeError: (error) => {
            //  console.error('QR code scan error:', error.message || 'No QR code found');
          },
          highlightScanRegion: true,
          preferredCamera: cameraFacing,
        }
      );

      qrScannerRef.current = qrScanner;
      await qrScanner.start();
    };

    startScanner();

    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.stop();
      }
    };
  }, [cameraFacing]);

  const switchCamera = () => {
    setCameraFacing((prevFacing) => (prevFacing === 'user' ? 'environment' : 'user'));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const result = await QrScanner.scanImage(file);
        const qrResult = typeof result === 'string' ? result : result.data || '';
        setScanResult(qrResult);
        console.log(qrResult);
        window.open(qrResult, '_blank'); // Open in new tab
      } catch (error) {
        console.error('Error scanning QR code from image:', error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center p-4">
      <p className="mb-4">
        Scan Result:
        {scanResult ?
          <a href={scanResult} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            {scanResult}
          </a>
          : 'No QR code scanned yet'
        }
      </p>
      <video
        ref={videoRef}
        className="w-80 h-80 mb-4 border-2 border-gray-300 rounded-md"
        style={{ objectFit: 'cover' }}
      />

      {/* <button
        className="block px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-4"
        onClick={switchCamera}
      >
        Switch Camera
      </button> */}
      <div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
      </div>


    </div>
  );
};

export default Scanner;
