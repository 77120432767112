import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInterceptor';
import { FaGoogle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import QR from "../Images/LoginQR.svg";
import googleIcon from "../Images/googleIcon.svg";
import { jwtDecode } from 'jwt-decode';

const Login = ({ setLogin }) => {
    const { t, i18n } = useTranslation();
    const [blur, setBlur] = useState("noblur");
    const [loading, setLoading] = useState("noloader");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [role, setRole] = useState(null);

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
    };

    const calculateUserAge = (dobStr) => {
        const dob = new Date(dobStr);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    };


    const login = async (e) => {
        e.preventDefault();
        setLoading("loader");
        setBlur("blur");
        const { email, password } = user;
        if (email && password) {
            try {
                const res = await axiosInstance.post(`/api/form/login`, user);
                setLoading("noloader");
                setBlur("noblur");
                setMessage(res.data.message);
                if (typeof res.data.userId !== "undefined") {
                    localStorage.setItem("user_login", res.data.userId);
                    localStorage.setItem("user_name", res.data.name);
                    localStorage.setItem('user_role', res.data.role);
                    localStorage.setItem('user_email', res.data.email);
                    const dobStr = res.data.age;
                    const age = calculateUserAge(dobStr);
                    localStorage.setItem('user_age', age);
                    localStorage.setItem('user_phone', res.data.phone);
                    localStorage.setItem("token", res.data.token);

                    if (res.data.role === 'Doctor'){
                        navigate('/doc/dashboard')
                    }
                    // navigate("/");
                    window.location.reload();
                }
            } catch (err) {
                console.log(err);
                setLoading("noloader");
                setBlur("noblur");
                setMessage("Login failed");
            } finally {

            }
        } else {
            setMessage("**All fields are required");
            setLoading("noloader");
            setBlur("noblur");
        }
    };

    const handlebutton = (e) => {
        e.preventDefault();
        navigate("/googlelogin");
    }

    return (
        <>
            <div className={blur}>
                <div className={loading}></div>
            </div>

            <div>
                <div className='text-center mt-[84px] flex flex-col gap-2'>
                    <h1 className='text-PrimaryBlue text-4xl'>Welcome Back!</h1>
                    <p className='text-PrimaryBlue font-semibold pt-2'>Scan QR <span className='text-lightGray'>code to sign in</span></p>
                    <img className='h-36 mt-3' src={QR} alt="QR" />
                </div>

                <div className="text-center text-sm text-lightGray font-medium mt-8">
                {t('register.or')}</div>

                <div className="mt-9 w-full">
                    {/* <h2>{t('register.login')}</h2> */}

                    <div className="flex flex-col gap-4">

                        <form className="flex flex-col gap-4">
                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Email</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500 w-full'
                                    type="text"
                                    name="email"
                                    value={user.email}
                                    placeholder={t('register.email')}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Password</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500 w-full'
                                    type="password"
                                    name="password"
                                    value={user.password}
                                    placeholder={t('register.password')}
                                    onChange={handleChange}
                                />
                            </div>

                            <p className="messaged text-sm">{message}</p>

                            <button className="button px-auto py-[10px] rounded-xl bg-PrimaryBlueBg text-white hover:scale-[1.02] transition-transform duration-300 ease-in-out text-center w-full" onClick={login}>
                                {t('register.login')}
                            </button>
                        </form>

                        {/* <div style={{ color: "black" }}>
                            <span className="xyz" onClick={() => navigate("/register")}>{t('register.register')}</span>
                        </div> */}

                        <div className="flex justify-center items-center">
                            <button
                                onClick={handlebutton}
                                className="button px-auto py-[10px] rounded-xl border border-gray-300 hover:scale-[1.02] transition-transform duration-300 ease-in-out text-center w-full flex items-center justify-center gap-2"
                            >
                                <img src={googleIcon} alt="googleicon" /> Sign in with Google
                            </button>
                        </div>
                        <p className=' text-lightGray font-semibold text-center'>Don't have an account? <span className='text-[#DB4437] cursor-pointer ' onClick={() => setLogin(false)}>Register Here</span></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
