import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import PdfToImage from '../PdfToImage';
import { useTranslation } from 'react-i18next';
const PDFJS = require("pdfjs-dist/webpack");
const Patientfiles = ({ patient_id, setCheckedTemplates, setpageNames, seturl_id }) => {
    // const { patient_id } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [pdfUrls, setPdfUrls] = useState([]);

    const handlenew = () => {
        seturl_id(" ")
        setCheckedTemplates([]);
        setpageNames([]);
    }

    useEffect(() => {
        window.handlenavigatefile = handlenavigatefile;
    }, []);

    const handlenavigatefile = async (urls, id) => {
        console.log(id)
        seturl_id(id)
        setCheckedTemplates([])
        setpageNames([])
        const templates = await Promise.all(
            urls.map(async (url, index) => {
                setpageNames(prevPageNames => {
                    return [...prevPageNames, ...url.pageNames]; // Collect page names for each URL
                });

                const loadingTask = PDFJS.getDocument(url.url);
                const pdf = await loadingTask.promise;

                const pageTemplates = [];

                // Loop through all pages of the PDF
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);

                    // Set the scaling factor
                    const scale = 1.5;
                    const viewport = page.getViewport({ scale });

                    // Create a canvas for rendering the page
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render the page to the canvas
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };

                    await page.render(renderContext).promise;

                    // Convert the canvas to a data URL (image format)
                    const imgDataUrl = canvas.toDataURL('image/png');

                    // Store each page as a separate template
                    pageTemplates.push({
                        index: `${index}-${pageNumber}`,  // Unique index for each page
                        template: {
                            id: `0123-${index}-${pageNumber}`, // Unique id for each page
                            url: imgDataUrl,                   // The image URL for this page
                            name: `Template ${index + 1} - Page ${pageNumber}`, // Template name
                        }
                    });
                }

                return pageTemplates;  // Return the array of page templates
            })
        );

        // Flatten the array of arrays and set the state
        setCheckedTemplates(prevTemplates => [...prevTemplates, ...templates.flat()]);
    };



    const data = patient_id?.patient_ID != "" ? patient_id?.patient_ID : patient_id?._id;

    // useEffect(() => {
    //     const fetchPdfUrls = async () => {
    //         const response = await axiosInstance.get(`/api/form/pdf-urls/patient/${data}`);
    //         console.log(response.data)
    //         setPdfUrls(response.data);
    //         if (!patient_id?.bio?.name)
    //             setPdfUrls([]);
    //     };

    //     fetchPdfUrls();
    // }, [patient_id]);

    useEffect(() => {
        const fetchPdfUrls = async () => {
            const response = await axiosInstance.get(`/api/form/pdf-urls/patient/${data}`);
            console.log(response.data);
            setPdfUrls(response.data);

            if (!patient_id?.bio?.name) {
                setPdfUrls([]);
            }
        };

        const timeoutId = setTimeout(() => {
            fetchPdfUrls();
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [patient_id]);


    return <div style={{ width: '100%', overflowX: 'auto' }} >
        {pdfUrls?.length ? (<table>
            <tbody>
                {pdfUrls
                    ?.map((pdfUrl) => (
                        <tr key={pdfUrl.timestamp}>
                            <td className='text-center ml-0'>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexShrink: 0,
                                        borderRadius: "50%",
                                        background: '#DDEEFF',
                                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

                                    }}

                                    onClick={handlenew}
                                >
                                    <p className='text-xl'>  +</p>
                                </div>
                            </td>
                            <td style={{ width: '100%', overflowX: 'auto' }}>

                                <div className="flex  space-x-4">
                                    {pdfUrl?.files?.map((file, fileIndex) => (
                                        <div key={fileIndex} className="flex  items-center flex-col">
                                            <div className="flex flex-row flex-nowrap space-x-4" onClick={() => handlenavigatefile(file?.urls, file?._id)}>
                                                <div>
                                                    <PdfToImage pdfUrl={file?.urls[0]?.url} style={{ height: "50px" }} />

                                                </div>

                                            </div>
                                            <p className="whitespace-nowrap">
                                                {new Date(file.urls[0]?.timestamp).toLocaleDateString()} {new Date(file.urls[0]?.timestamp).toLocaleTimeString()}

                                            </p>
                                            <p className="whitespace-nowrap">{file?.urls[0]?.doctor_name}</p>
                                        </div>
                                    ))}
                                </div>

                            </td>

                        </tr>
                    ))}
            </tbody>
        </table>) : ""}
    </div>;
}


export default Patientfiles;