import React from 'react';
import Navbar from "../Component/Navbar";
import Hero from "../Component/Hero";
import Companies from '../Component/Companies';
import Features from '../Component/Features';
// import Testimonial from '../Component/Testimonial';
import Faq from '../Component/Faq';
// import Services from '../Component/Services';
import Trial from '../Component/Trial';
import Footer from '../Component/Footer';

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Companies />
      <Features />
      {/* <Testimonial /> */}
      <Faq />
      <Trial />
      <Footer />
    </div>
  )
}

export default Home
