import React from 'react';
import FeaturesIcon1 from "../Images/features1.svg";
import FeaturesIcon2 from "../Images/features2.svg";
import FeaturesIcon3 from "../Images/features3.svg";

const Features = () => {

    const FeatureCards = [
        {
            icon: FeaturesIcon1,
            tagline: "AR Surgical Planning",
            desc: "Create or add your letterheads, forms and diagrams in an instant.",
        },
        {
            icon: FeaturesIcon2,
            tagline: "Simulation & Training",
            desc: "Everything is shareable as a QR code.",
        },
        {
            icon: FeaturesIcon3,
            tagline: "Multimodal AI Copilot",
            desc: "Implicit consent for security that doesn’t limit convenience.",
        },
        {
            icon: FeaturesIcon1,
            tagline: "Multimodal AI Copilot",
            desc: "Informed patients, calm patients.",
        },
        {
            icon: FeaturesIcon3,
            tagline: "Multimodal AI Copilot",
            desc: "A complete trail for all administrative tasks.",
        },
        {
            icon: FeaturesIcon2,
            tagline: "Multimodal AI Copilot",
            desc: "Maximum changeability to correct mistakes instantly.",
        },
        {
            icon: FeaturesIcon3,
            tagline: "Multimodal AI Copilot",
            desc: "Integrable with your EMRS, HMIS and ERP. Supports ABDM, ABHA and UHI.",
        },
        {
            icon: FeaturesIcon2,
            tagline: "Multimodal AI Copilot",
            desc: "We work as hard as residents to ensure that residents can focus on the patient.",
        },
        {
            icon: FeaturesIcon1,
            tagline: "Multimodal AI Copilot",
            desc: "On premise, cloud or both, servers the way you want them.",
        },
        
    ]

    return (
        <section className='px-mobile xl:px-xlarge lg:px-large 2xl:max-w-[1440px] 2xl:mx-auto py-24'>
        <div>
            <p className='text-Blue font-semibold text-center'>Features</p>
            <h1 className='text-4xl font-semibold text-Heading text-center pt-3 pb-5'>
                ePrescription trails with immutable changeability.
            </h1>
            <p className='text-[#667085] text-center'>Handwritten, flexible, portable, shareable. Just like paper.
            Seamless healthcare is back for all your needs related to  compliances and reconcilliation.</p>
        </div>

        <div className='grid lg:grid-cols-3 grid-cols-1 gap-16 pt-16 justify-items-center items-start'>
            {FeatureCards.map((item, index) => (
                <div className='flex flex-col items-center justify-center max-w-[384px]'>
                    <img className='pb-5' src={item.icon} alt="icon" />
                    {/* <p className='text-Heading text-xl font-medium pb-2'>{item.tagline}</p> */}
                    <p className='text-SubGray text-center font-medium text-lg'>{item.desc}</p>
                </div>
            ))}

        </div>
    </section>
    )
}

export default Features
