import React from 'react'
import ButtonBlue from '../UI/ButtonBlue';
import PauseIcon from "../Images/pauseIcon.svg";
import HeroImage from "../Images/heroImage.svg";

const Hero = () => {
  return (
    <section className='px-mobile xl:px-xlarge lg:px-large 2xl:max-w-[1440px] 2xl:mx-auto sm:py-24 py-16 flex flex-col items-center'>
      {/* heading */}
      <div className='flex flex-col items-center justify-center gap-6 max-w-[1024px] mx-auto '>
        <h1 className='font-semibold text-headingSmall md:text-headingLarge text-Heading text-center'>Space Age Healthcare</h1>
        <p className='text-SubGray text-base md:text-xl max-w-[768px] text-center leading-7 md:leading-8'>Designed for corporates and hospital emergencies</p>
      </div>

      <div className='flex items-center flex-col-reverse md:flex-row justify-center gap-3 pt-12 w-full md:w-[380px]'>
        <button className='w-full rounded-lg py-3 px-7 font-semibold md:text-lg text-base flex items-center justify-center gap-1 border border-[#D0D5DD]'>
          <img src={PauseIcon} alt="pause" />
          <p>Demo</p>
        </button>
      
          <ButtonBlue div={"w-full"} text={"Sign Up"} link={"/registration"}/>   
      </div>

      <img src={HeroImage} alt="demo dashboard" className='pt-16 hidden md:block'/>
    </section>
  )
}

export default Hero
