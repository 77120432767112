import React from 'react';
import dots from "../../../../Images/verticalThreeDots.svg";

const ActivityBoxes = (props) => {
    return (
        <div className='p-6 rounded-lg border border-[#EAECF0] shadow flex-grow'>
            <div className='flex items-center justify-between'>
                <h1 className='font-medium'>{props.heading}</h1>
                <img src={dots} alt="dots" />
            </div>
            <div className='mt-6'>
                <div>
                    <p className='text-4xl font-semibold'>{props.stat}</p>
                </div>
                <div>
                    
                </div>
            </div>
        </div>
    )
}

export default ActivityBoxes
