import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import PdfToImage from '../PdfToImage';
import { useTranslation } from 'react-i18next';
import { jsPDF } from 'jspdf';
const PDFJS = require("pdfjs-dist/webpack");
const Report = ({ setviewpdf }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [pdfUrls, setPdfUrls] = useState([]);
    const userRole = localStorage.getItem('user_role');


    const patient_ID = userRole == 'NonMedical' ? localStorage.getItem('user_login') : "";
    const patient_name = userRole == 'NonMedical' ? localStorage.getItem('user_name') : "";

    const fetchPdfUrls = async () => {
        const response = await axiosInstance.get(`/api/form/pdf-urls/patient/${localStorage.getItem("user_login")}`);
        console.log(response.data)
        setPdfUrls(response.data);
    };

    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'bxva8g23'); // Replace with your upload preset

        const response = await fetch('https://api.cloudinary.com/v1_1/drz2uk3vm/upload', {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data.secure_url;
    };

    const saveToDatabase = async ({ url, name, url_id }) => {
        const data = {
            cloudinaryUrl: url,
            doctor_id: " ",
            patient_id: patient_name,
            patient_uploadId: patient_ID,
            patient_token: " ",
            doctor_name: " ",
            pageNames: [name],
            url_id: url_id,
            qruuid: "",
            news: true
        };
        console.log(data)
        const token = localStorage.getItem('token');
        try {
            const response = await axiosInstance.post(`/api/form/patient-upload/`, {
                url,
                name,
                patient_ID
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await axiosInstance.post(`/api/form/patientuploadfile`, data);
            return response.data;
        } catch (error) {
            console.error('Error saving URL to database:', error);
            throw error;
        }
    };

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };



    const convertImageToPdf = async (file) => {
        try {
            const imageDataUrl = await readFileData(file);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt', // Set units to points
                format: 'a4',
            });

            // Get A4 dimensions
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            // Load the image to get its dimensions
            const img = new Image();
            img.src = imageDataUrl;

            // Return a promise that resolves with the PDF blob
            return new Promise((resolve, reject) => {
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    // Calculate dimensions to maintain aspect ratio
                    let width, height;
                    if (imgWidth / imgHeight > pageWidth / pageHeight) {
                        width = pageWidth - 20; // 10pt margin on each side
                        height = (imgHeight * width) / imgWidth;
                    } else {
                        height = pageHeight - 20; // 10pt margin on top and bottom
                        width = (imgWidth * height) / imgHeight;
                    }

                    // Center the image on the page
                    const x = (pageWidth - width) / 2;
                    const y = (pageHeight - height) / 2;

                    // Add image to PDF
                    pdf.addImage(imageDataUrl, 'JPEG', x, y, width, height);

                    // Output the PDF as a Blob and resolve the promise
                    const pdfBlob = pdf.output('blob');
                    resolve(pdfBlob);
                };

                img.onerror = (error) => {
                    reject(new Error('Failed to load image for PDF conversion'));
                };
            });
        } catch (error) {
            console.error('Error converting image to PDF:', error);
            throw new Error('Failed to convert image to PDF');
        }
    };


    const handleItChangeupload = async (e, url_id) => {
        let selectedFile = e.target.files[0];
        console.log("Selected file:", selectedFile);

        if (selectedFile) {
            setUploadStatus('Uploading'); // Set status to 'Uploading'

            try {
                const fileType = selectedFile.type;
                let cloudinaryUrl = '';

                if (fileType === 'application/pdf') {
                    console.log("Processing PDF...");

                    cloudinaryUrl = await uploadToCloudinary(selectedFile);
                    console.log(`Uploaded PDF to Cloudinary: ${cloudinaryUrl}`);

                    // Save the Cloudinary URL to the database
                    await saveToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}`, url_id: url_id });

                } else if (fileType === 'image/jpeg' || fileType === 'image/png') {

                    const pdfBlob = await convertImageToPdf(selectedFile);
                    cloudinaryUrl = await uploadToCloudinary(pdfBlob, `${selectedFile.name}.pdf`);
                    await saveToDatabase({ url: cloudinaryUrl, name: selectedFile.name, url_id: url_id });
                } else {
                    console.error('Unsupported file type');
                    setUploadStatus('Upload Failed'); // Set status to 'Upload Failed'
                    return;
                }
                setUploadStatus('Upload Completed');
                setTimeout(() => {
                    setUploadStatus('');
                }, 2000);
                fetchPdfUrls();

            } catch (error) {
                console.error('Error handling file:', error);
                setUploadStatus('Upload Failed');
                setTimeout(() => {
                    setUploadStatus('');
                }, 2000);
            }
        }
    };


    const handlefileupload = async (e, ids) => {
        handleItChangeupload(e, ids);
    };

    const [uploadStatus, setUploadStatus] = useState('');
    // const handleDownload = async (pdfUrl, doctor_name, patient_name) => {
    //     const today = new Date();
    //     const year = today.getFullYear().toString().padStart(2, '0');
    //     const month = (today.getMonth() + 1).toString().padStart(2, '0');
    //     const day = today.getDate().toString().padStart(2, '0');
    //     const hours = today.getHours().toString().padStart(2, '0');
    //     const minutes = today.getMinutes().toString().padStart(2, '0');

    //     const filename = `${year}${month}${day}_${hours}${minutes}_${doctor_name}_${patient_name}.pdf`;
    //     console.log(pdfUrl)
    //     const secureUrl = pdfUrl?.replace(/^http:/, 'https:');
    //     console.log(secureUrl);
    //     try {
    //         const response = await fetch(secureUrl);
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(new Blob([blob]));

    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute("download", filename); // Set the filename for the download
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();

    //         // Release the object URL after the download
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Failed to download PDF:', error);
    //     }
    // };

    const handleDownload = async (e) => {
        setUploadStatus('Uploading'); // Set status to 'Uploading' when download begins

        try {
            if (window.handleItChangeupload) {
                await window.handleItChangeupload(e);
                setUploadStatus('Upload Completed');
            }


        } catch (error) {
            console.error('Error in handleDownload:', error);
            setUploadStatus('Upload Failed');
        }
    };
    const handlenavigate = (urls) => {

        setviewpdf(urls);
        navigate(`/viewpdf`)

    }



    useEffect(() => {
        fetchPdfUrls();
    }, []);

    return <div style={{ width: '90%', overflowX: 'auto', margin: "auto" }}>
        <div div className="tab-pane" >
            {pdfUrls?.length ? (<table>
                <thead>
                    <tr>
                        <th className='text-center'>{t('details')}</th>
                        <th className='text-center'>{t('files')}</th>
                    </tr>
                </thead>
                <tbody>
                    {pdfUrls
                        ?.sort((a, b) => b.timestamp - a.timestamp)
                        ?.map((pdfUrl) => (
                            <tr key={pdfUrl.timestamp}>
                                <td className='text-center'>
                                    <div className='mb-4'>{pdfUrl?.patient_name}</div>
                                    <div style={{ width: "40%", margin: "auto" }}>
                                        <label
                                            htmlFor="fileInput"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexShrink: 0,
                                                borderRadius: "50%",
                                                background: '#DDEEFF',
                                                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

                                            }}
                                        >
                                            +
                                        </label>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            multiple
                                            onChange={(e) => handlefileupload(e, " ")}
                                            className="hidden"
                                        />
                                    </div>
                                    {
                                        uploadStatus ? <div className='mt-4 red'>Upload Status: {uploadStatus}</div> : ""}
                                </td>
                                <td>
                                    {pdfUrls?.length > 0 && (
                                        <div className="flex  space-x-4">
                                            {pdfUrls[0]?.files?.map((file, fileIndex) => (
                                                <div key={fileIndex} className="flex  items-center flex-col">
                                                    <div className="flex flex-row flex-nowrap space-x-4" onClick={() => handlenavigate(file?.urls)}>
                                                        <div style={{ width: "150px" }}>

                                                            <PdfToImage pdfUrl={file?.urls[0]?.url} />


                                                        </div>

                                                    </div>
                                                    <p className="whitespace-nowrap">
                                                        {new Date(file.urls[0]?.timestamp).toLocaleDateString()} {new Date(file.urls[0]?.timestamp).toLocaleTimeString()}

                                                    </p>
                                                    <p className="whitespace-nowrap">{file.urls[0]?.doctor_name}</p>

                                                    <div style={{ width: "40%", margin: "auto" }}>
                                                        <label
                                                            htmlFor={`fileInput-${file?._id}`}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                flexShrink: 0,
                                                                borderRadius: "50%",
                                                                background: '#DDEEFF',
                                                                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

                                                            }}
                                                        >
                                                            +
                                                        </label>
                                                        <input
                                                            id={`fileInput-${file?._id}`}
                                                            type="file"
                                                            multiple
                                                            onChange={(e) => handlefileupload(e, file?._id)}
                                                            className="hidden"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </td>
                            </tr>
                        ))}
                </tbody>
            </table >) : (<>
                < label
                    htmlFor="uploadinput"
                    className="download-btn"
                >
                    Create a new file
                </label>
                <input
                    id="uploadinput"
                    type="file"
                    multiple
                    onChange={handleItChangeupload}
                    className="hidden"
                />
                {
                    uploadStatus ? <div className='mt-4 red'>Upload Status: {uploadStatus}</div> : ""}</>)}
        </div >

    </div >;
}

export default Report;