import React from 'react';
import NavLogo from "../Images/NavLogo.svg";
import logoIcon from "../Images/NavLogo.svg"
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className='px-mobile pb-4 pt-4 lg:pt-8  xl:px-36 border-t-2 border-gray-200 '>
      <div className='2xl:max-w-[1440px] 2xl:mx-auto'>
        <div className='lg:flex lg:justify-between items-center'>
          {/* left side  */}
          <div className='pt-10 lg:w-2/4'>
            {/* brand logo and tagline */}
            <div>
              <Link to={"/"}>
                <div className='flex items-center gap-3 flex-col sm:flex-row'>
                  <img src={logoIcon} alt="EaseMyMed" className='h-16'/>
                </div>
              </Link>
            </div>

            {/* react out  */}
            {/* <div className='flex gap-2 pb-6 flex-wrap mt-8'>
              <button className='py-2 px-2 text-sm bg-[#5b5a5a] rounded-md text-white'>support@axai.ai</button>
              <button className='py-2 px-2 text-sm bg-[#5b5a5a] rounded-md text-white'>yashraj@axai.ai</button>
            </div> */}

            {/* disclaimer  */}
            {/* <div>
              <p className='text-[#646464] text-sm'>Disclaimer: You may have noticed some brand logos used on this website to indicate where you, as a user, may or may not have spent money. We don’t endorse these brands. Nor do these brands endorse us. The logos of the specific brands are owned by them.</p>
            </div> */}
          </div>

          {/* right side  */}
          <div className='text-right pt-16 sm:pt-8 '>
            <div>
              <p className='text-sm font-medium text-[#646464]'>Locate Us</p>
              {/* <p>Ground floor, CIIE.CO,</p> */}
              <p>Indian Institute of Technology Jodhpur</p>
              <p>Rajasthan, India</p>
            </div>

            {/* map */}
            <div className='pt-6'>
              <iframe title='map' className='rounded-xl w-full h-full shadow-md' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.525761652536!2d73.10865859678952!3d26.4710115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418c5ea672337b%3A0xb6c9a5a9b08db22e!2sIndian%20Institute%20of%20Technology%20(IIT)%2C%20Jodhpur!5e0!3m2!1sen!2sin!4v1725535103866!5m2!1sen!2sin" loading='lazy'></iframe>
            </div>
          </div>
        </div>

        <div
          className='pt-20'>
          <div className='flex justify-center items-center pt-5 gap-10 '>
            <p className='text-[10px] text-lightGray sm:text-sm'>All Rights Reserved By EaseMyMed</p>
            <p className='text-[10px] text-lightGray sm:text-sm'>Built In INDIA</p>
          </div>
        </div>

      </div>

    </footer>
  )
}

export default Footer