import React from 'react';
import Button from '../UI/ButtonBlue';

const Trial = () => {
    return (
        <section className=' mb-24 px-mobile xl:px-xlarge lg:px-large 2xl:max-w-[1440px] 2xl:mx-auto'>
            <div className='bg-[#F9FAFB] rounded-lg shadow-sm'>
                <div className='py-24 px-2 flex flex-col items-center gap-5 max-w-[768px] mx-auto'>
                    {/* <div className='bg-[#F9F5FF] rounded-2xl py-1 px-3'>
                        <p className='text-Blue font-medium text-sm'>Benefits</p>
                    </div> */}
                    <h1 className='text-Heading text-4xl font-semibold text-center'>Coming Soon</h1>
                    <p className='text-SubGray text-xl text-center'>AI that understands what the doctor speaks or writes and turns it into actionable data. <span className='text-[#0072CE] font-medium'>Preorder Now</span>
                    </p>
                </div>
            </div>
        </section>
    )
}


export default Trial
