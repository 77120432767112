import React from 'react';

const ConfirmationPage = () => {
  return (
    <div>
      <h2>Form Submission Successful</h2>
      <p>Thank you for your submission! Your form has been received.</p>
    </div>
  );
};

export default ConfirmationPage;
