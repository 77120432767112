import React, { useState, useEffect } from 'react';
import axiosInstance from "./axiosInterceptor";
import { Document, Page } from 'react-pdf';
import { jsPDF } from 'jspdf';
const PDFJS = require("pdfjs-dist/webpack");

const Upload = () => {
  const [pageNames, setpageNames] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const userRole = localStorage.getItem('user_role');

  const patient_ID = userRole == 'NonMedical' ? localStorage.getItem('user_login') : "";
  const patient_name = userRole == 'NonMedical' ? localStorage.getItem('user_name') : "";

  useEffect(() => {
    fetchImages(); // Fetch images when the component mounts
  }, []);

  const fetchImages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/form/patient-images/${localStorage.getItem("user_login")}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setImages(response.data); // Set images in state
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleview = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the selected image URL
    setShowModal(true); // Show the modal
  };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'bxva8g23'); // Replace with your upload preset

    const response = await fetch('https://api.cloudinary.com/v1_1/drz2uk3vm/upload', {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data.secure_url;
  };

  const saveToDatabase = async ({ url, name }) => {
    const data = {
      cloudinaryUrl: url,
      doctor_id: " ",
      patient_id: patient_name,
      patient_token: patient_ID,
      doctor_name: " ",
    };
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post(`/api/form/patient-upload/`, {
        url,
        name,
        patient_ID
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      await axiosInstance.post(`/api/form/patientuploadfile`, data);
      return response.data;
    } catch (error) {
      console.error('Error saving URL to database:', error);
      throw error;
    }
  };

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  // // Convert PDF to an array of images (one image per page)
  // const convertPdfToImages = async (file) => {
  //   const images = [];
  //   try {
  //     const data = await readFileData(file);
  //     console.log(data)
  //     const pdf = await PDFJS?.getDocument(data).promise;
  //     console.log("pdf", pdf)
  //     const canvas = document.createElement("canvas");

  //     for (let i = 0; i < pdf?.numPages; i++) {
  //       const page = await pdf.getPage(i + 1);
  //       const viewport = page.getViewport({ scale: 1 });
  //       const context = canvas.getContext("2d");

  //       canvas.height = viewport.height;
  //       canvas.width = viewport.width;

  //       await page.render({ canvasContext: context, viewport: viewport }).promise;

  //       images.push(canvas.toDataURL());
  //     }

  //     canvas.remove();
  //   } catch (error) {
  //     console.error('Error converting PDF to images:', error);
  //   }

  //   return images;
  // };

  const convertImageToPdf = async (file) => {
    try {
      const imageDataUrl = await readFileData(file);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt', // Set units to points
        format: 'a4',
      });

      // Get A4 dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Load the image to get its dimensions
      const img = new Image();
      img.src = imageDataUrl;

      // Return a promise that resolves with the PDF blob
      return new Promise((resolve, reject) => {
        img.onload = () => {
          const imgWidth = img.width;
          const imgHeight = img.height;

          // Calculate dimensions to maintain aspect ratio
          let width, height;
          if (imgWidth / imgHeight > pageWidth / pageHeight) {
            width = pageWidth - 20; // 10pt margin on each side
            height = (imgHeight * width) / imgWidth;
          } else {
            height = pageHeight - 20; // 10pt margin on top and bottom
            width = (imgWidth * height) / imgHeight;
          }

          // Center the image on the page
          const x = (pageWidth - width) / 2;
          const y = (pageHeight - height) / 2;

          // Add image to PDF
          pdf.addImage(imageDataUrl, 'JPEG', x, y, width, height);

          // Output the PDF as a Blob and resolve the promise
          const pdfBlob = pdf.output('blob');
          resolve(pdfBlob);
        };

        img.onerror = (error) => {
          reject(new Error('Failed to load image for PDF conversion'));
        };
      });
    } catch (error) {
      console.error('Error converting image to PDF:', error);
      throw new Error('Failed to convert image to PDF');
    }
  };


  const handleItChangeupload = async (e) => {
    let selectedFile = e.target.files[0];
    console.log("this is ", selectedFile);
    if (selectedFile) {
      try {
        const fileType = selectedFile.type;
        let cloudinaryUrl = '';

        if (fileType === 'application/pdf') {
          const cloudinaryUrl = await uploadToCloudinary(selectedFile);
          console.log(`Uploaded pdf to Cloudinary:`, cloudinaryUrl);

          const savedTemplate = await saveToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}` });
          // }));


        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {

          const pdfBlob = await convertImageToPdf(selectedFile);
          cloudinaryUrl = await uploadToCloudinary(pdfBlob, `${selectedFile.name}.pdf`);
          const savedImage = await saveToDatabase({ url: cloudinaryUrl, name: selectedFile.name });
        } else {
          console.error('Unsupported file type');
          return;
        }

        // Save the Cloudinary URL to the database


        // Fetch and update the images state with the latest images from the backend
        fetchImages();
        return;

      } catch (error) {
        console.error('Error handling file:', error);
        return;
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    window.handleItChangeupload = handleItChangeupload;  // Expose to global scope

  }, []);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    console.log(numPages)
  }

  return (
    <div className="p-4">
      <div className="gap-4 flex inset-0  ">

        {images.map((image, index) => (
          <div className=" flex cursor-pointer ">
            <Document
              file={image.url}
              onLoadSuccess={onDocumentLoadSuccess}
              onClick={() => handleview(image.url)}
            >

              {Array.from(
                { length: numPages },
                (_, index) => (
                  <div key={`page_${index + 1}`}>
                    <Page
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={100}
                      height={100}
                    />
                  </div>
                )
              )}

            </Document>
          </div>
        ))
        }

        <label
          htmlFor="fileInput"
          className="w-24 h-24 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center text-gray-400 text-3xl cursor-pointer hover:bg-gray-100 transition-colors"
        >
          +
        </label>
        <input
          id="fileInput"
          type="file"
          multiple
          onChange={handleItChangeupload}
          className="hidden"
        />
      </div >

      {/* Modal for displaying full-screen image */}
      {
        showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">

              <Document
                file={selectedImage}
                onLoadSuccess={onDocumentLoadSuccess}
                className="max-w-screen-sm max-h-screen object-contain"
              >
                {Array.from(
                  { length: numPages },
                  (_, index) => (
                    <div key={`page_${index + 1}`}>
                      <Page
                        pageNumber={index + 1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={500}
                        height={500}
                      />
                      <p>Page {index + 1} of {numPages}
                      </p>
                    </div>
                  )
                )}
              </Document>
              <button
                onClick={closeModal}
                className="absolute top-2 right-8 bg-white p-2 rounded-full text-black text-xl"
              >
                &times;
              </button>
            </div>
          </div>
        )
      }
    </div >
  );
};

export default Upload;
