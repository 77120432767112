import React, { useState, useEffect } from 'react';
import { FaHospital } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qrimage from './assets/frame.png'

// import ReactWhatsapp from 'react-whatsapp';

const Qrcode = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [url, setUrl] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function generateQRCode(dataUrl) {
    const encodedUrl = encodeURIComponent(dataUrl);
    return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodedUrl}`;
  }

  useEffect(() => {
    const Url = `https://dpp.baavlibuch.com/newpatient/${localStorage.getItem("user_login")}`
    const qrCodeImageUrl = generateQRCode(Url);
    setUrl(qrCodeImageUrl)
  }, []);

  const handlePhoneInputChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const whatsappURL = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(url)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div>
      <div className=''>
        <Link to='/formcreation' className="flex justify-center items-center rounded-sm font-bold w-fit pl-2 pr-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
          <p className="whitespace-nowrap"> {t('Create Form')}</p>
        </Link>
        <div className="flex h-[90%] justify-center items-center flex-wrap text-xl  mt-4">
          <button onClick={() => navigate("/scanner")} className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">
            {t('scan_qr')}
          </button>

        </div>
      </div>
      <div className="flex w-[100%] h-[90%] justify-center items-center ">

        <div className="flex w-[100%] h-[90%] justify-center items-center " >
          <div>
            <img src={url} alt=" man" style={{ height: "150px", width: "160px", marginTop: "50px" }} />
            <h4 className="text-2xl text-center font-bold whitespace-nowrap ">{localStorage.getItem("user_name")}</h4>
          </div>
        </div>



        <div className="flex w-[100%] h-[90%] justify-center items-center   ">

          <div>
            <img src={qrimage} alt=" man" style={{ height: "190px", width: "190px", marginTop: "50px" }} />
            <h4 className="text-2xl text-center font-bold "> Hospital</h4>
          </div>


        </div>


      </div >
    </div >
  );
};

export default Qrcode;

