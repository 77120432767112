import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // To extract the uuid from the URL
import axiosInstance from '../axiosInterceptor';
import Example from '../PdfViewer';

const VerifyFile = ({ setviewpdf }) => {
    const { uuid } = useParams();
    const [fileData, setFileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);





    useEffect(() => {
        const fetchFileData = async () => {
            console.log(uuid)
            try {
                const response = await axiosInstance.post(`/api/form/patientfileQr`, { uuid });
                setFileData(response.data);

            } catch (err) {
                setError('Error fetching file data.');
                console.log(err)
            } finally {
                setLoading(false);
            }
        };
        if (uuid) {
            fetchFileData();
        }
    }, [uuid]);
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            {fileData ? (
                <div>
                    <Example file_id={fileData.urls} />
                </div>
            ) : (
                <p>No file data available.</p>
            )}
        </div>
    );
};

export default VerifyFile;
