import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useParams } from 'react-router-dom';
const PDFJS = require("pdfjs-dist/webpack");

const Addpatient = ({ setqron }) => {
    const [profession, setProfession] = useState('Doctor');
    const userRole = localStorage.getItem('user_role');
    const [users, setUsers] = useState([]);
    const [patient, setpatient] = useState(0);

    const [formData, setFormData] = useState({
        bio: { name: '', age: '', sex: '', id: 'none', phoneNumber: '', address: '', paid: false, abha: '', father_name: '' },
        doctor_ID: '', patient_ID: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            bio: {
                ...prevFormData.bio,
                [name]: value
            }
        }));
        console.log(formData)
    };


    const handleSubmit = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            const response = await axiosInstance.post(`/api/form`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log(response.data);
            // Handle success
            alert('Patient added successfully');
            setFormData({
                bio: { name: '', age: '', sex: '', id: 'none', phoneNumber: '', address: '', paid: false, abha: '', father_name: '' },
                doctor_ID: '', patient_ID: '',
            });
            setqron(false)

            localStorage.setItem('moveLastToFirstExecuted', 'true');
            window.location.reload();




        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error
        }
    };

    return (
        <>
            <div className="max-w-md mx-auto p-12 w-[110%]  rounded-md text-xl  relative" >
                <div className="relative top-0 left-48 cursor-pointer" onClick={() => setqron(false)}>
                    <img className="h-10 w-10" src="/cross2.png" alt="Close" />
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="patientName" className="block mb-1">Patient Name:</label>
                        <input type="text" id="patientName" name="name" value={formData.bio.name} onChange={handleChange} className="w-full border-2  border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="patientName" className="block mb-1">Father's Name:</label>
                        <input type="text" id="father_name" name="father_name" value={formData.bio.father_name} onChange={handleChange} className="w-full border-2  border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div clas sName="mb-4">
                        <label htmlFor="age" className="block mb-1">Age:</label>
                        <input type="number" id="age" name="age" value={formData.bio.age} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="gender" className="block mb-1">Sex:</label>
                        <select id="gender" name="sex" value={formData.bio.sex} onChange={handleChange} className="w-full border-2 border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400">
                            <option value="">-- Select Gender --</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Others</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block mb-1">Abha Id:</label>
                        <input type="text" id="abha" name="abha" value={formData.bio.abha} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block mb-1">Phone Number:</label>
                        <input type="number" id="phoneNumber" name="phoneNumber" value={formData.bio.phoneNumber} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>

                    <div className='mt-2'>

                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 md:h-10 lg:h-12 xl:h-14">Submit</button>

                    </div>
                </form>
            </div>



        </>
    );
};

export default Addpatient;
