import React, { useState } from 'react';
import Signup from "../../Component/Register"
import NavLogo from "../../Images/NavLogo.svg";
import Login from "../../Component/Login";
import { Link } from 'react-router-dom';

const RegistrationMain = () => {

    const [login, setLogin] = useState(false);

    return (
        <section className='flex justify-between px-mobile xl:px-xlarge lg:px-large 2xl:max-w-[1440px] 2xl:mx-auto h-full py-24'>
            <div className='mx-auto'>
                <div>
                    {/* <Link to={"/"}>
                        <img className='h-16' src={NavLogo} alt="EaseMyMed" />
                    </Link> */}
                    <h1 className='text-4xl font-semibold text-Heading'>Organization name/ logo</h1>
                </div>

                {login ? (
                    <Login setLogin={setLogin} />
                ) : (
                    <Signup setLogin={setLogin} />
                )}

                <div className='mt-36'>
                    <Link to={"/"}>
                        <img className='mx-auto h-8' src={NavLogo} alt="EaseMyMed" />
                    </Link>
                </div>
            </div>

        </section>
    )
}

export default RegistrationMain
