import React, { useState, useEffect } from 'react';
// import { doc, getDocs, getDoc, collection, query } from 'firebase/firestore';
// import { db } from '../../../Firebase/FirebaseConfig';
// import { UserAuth } from '../../../Context/AuthContext';
// import Button from "../../../UI/Button";
// import ArIcon from "../../../Images/augmented-reality.png";
// import Modal from '../../../UI/Modal';

const PatTable = (props) => {
    const [sortByDateAscending, setSortByDateAscending] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);

    // const handleRowClick = (rowData) => {
    //     setSelectedRow(rowData);
    // };

    // const handleSortByDate = () => {
    //     setSortByDateAscending(!sortByDateAscending);
    // };

    // const convertDate = (dateString) => {
    //     const [day, month, year] = dateString.split('/');
    //     return new Date(year, month - 1, day);
    // };

    // const sortedAppointments = props.appointments.slice().sort((a, b) => {
    //     const dateA = convertDate(a.date);
    //     const dateB = convertDate(b.date);

    //     return sortByDateAscending ? dateB - dateA : dateA - dateB;
    // });

    return (
        <div className='overflow-x-auto overflow-y-auto rounded-lg shadow-md  w-full'>
            <table className='border-collapse border-spacing-0 w-full border border-gray-200'>
                <thead>
                    <tr className='text-xs font-medium text-[#667085] border-b border-gray-200'>
                        {props.tableHead.map((value, index) => (
                            <th className='text-nowrap text-left p-3' key={index}>
                                {value === 'Date' ? (
                                    ''
                                    // <button
                                    //     onClick={handleSortByDate}
                                    //     className="focus:outline-none"
                                    // >
                                    //     {value} {sortByDateAscending ? '↑' : '↓'}
                                    // </button>
                                ) : (
                                    value
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                        <tr className='border-b border-gray-200 text-nowrap p-3 hover:bg-[#dee5f9] hover:cursor-pointer hover:transition-all hover:duration-300 hover:ease-in-out'>
                            <td className='text-sm font-semibold p-3'>
                                <p>{props.patient}</p>
                            </td>
                            <td className='text-sm text-[#383c46] p-3'>
                                <p>{props.condition}</p>
                            </td>
                            <td className='text-sm text-[#383c46] p-3'>
                                <p>{props.status}</p>
                            </td>
                            <td className='p-3 text-sm text-[#383c46]'>
                                {props.appointment}
                            </td>
                            <td className='p-3 text-sm text-[#383c46]'>
                                {props.summary}
                            </td>
                        </tr>
                    
                </tbody>
            </table>

        </div>
    );
};

export default PatTable;