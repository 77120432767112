import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInterceptor';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Qrcode from '../Qrcode';
import Receptionistregistration from "./Addpatient";
import { useNavigate } from 'react-router-dom';
import PdfToImage from '../PdfToImage';
import { useTranslation } from 'react-i18next';
import { socketClient } from "../socket";

const Hospital = ({ formData, setviewpdf }) => {
    const { t, i18n } = useTranslation();
    const [bios, setBios] = useState([]);
    const [display, setdisplay] = useState([]);
    const navigate = useNavigate();
    const [qron, setqron] = useState(false);
    const [newBio, setNewBio] = useState({
        bio: {
            name: '',
            age: '',
            sex: '',
            id: 'none',
            paid: false,
        },
        doctor_ID: localStorage.getItem('user_login') || ''
    });

    const [doctors, setDoctors] = useState([]);
    const departments = ['Cardiology', 'Neurology', 'Oncology', 'Pediatrics'];

    const [imageBase64, setImageBase64] = useState('');
    const [pdfUrls, setPdfUrls] = useState([]);
    const [selectedTab, setSelectedTab] = useState('queue');
    const [editingIndex, setEditingIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');


    const [searchType, setSearchType] = useState('patient_name');
    const handleSearchChange = async (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.trim() === '') {
            setBios(display);
        } else {
            const matchedBios = display.filter(bio => {
                // Search based on the selected searchType
                if (searchType === 'patient_name') {
                    return bio?.bio?.name?.toLowerCase().includes(term.toLowerCase());
                } else if (searchType === 'abha_id') {
                    return bio?.bio?.abha?.toLowerCase().includes(term.toLowerCase());
                } else if (searchType === 'phone_number') {
                    return bio?.bio?.phoneNumber?.includes(term);
                }
                return false;
            });
            setBios(matchedBios); // Update bios with matched results
        }
    }

    useEffect(() => {
        const storedImage = localStorage.getItem('doctorqr');
        if (storedImage) {
            setImageBase64(storedImage);
        }
    }, []);

    useEffect(() => {
        let intervalId;

        const fetchBiosAndPdfUrls = async () => {
            try {
                // Fetch bios
                const token = localStorage.getItem('token');
                const biosResponse = await axiosInstance.get('/api/form/reception', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const bios = biosResponse.data;

                // Fetch PDF URLs
                const pdfUrls = await getPdfUrls();

                // Map bios with corresponding PDF URLs
                const mappedData = bios.map(bio => {
                    // if (!bio?.patient_ID) {
                    //     return {
                    //         ...bio,
                    //         pdfUrls: []
                    //     };
                    // }

                    const matchingPdfUrls = pdfUrls.filter(pdf => {
                        if (bio?.patient_ID != "" && pdf?.patient_uploadId != " ") {
                            return pdf?.patient_uploadId == bio?.patient_ID;
                        }
                        if (pdf?.patient_id != "")
                            return pdf?.patient_id == bio?._id;
                    });

                    console.log("matching", matchingPdfUrls)

                    return {
                        ...bio,
                        pdfUrls: matchingPdfUrls || []
                    };
                });

                const finalData = [...mappedData];
                console.log(finalData);
                setBios(finalData);
                setdisplay(finalData)
                const executed = localStorage.getItem('moveLastToFirstExecuted');
                if (executed === 'true') {
                    localStorage.removeItem('moveLastToFirstExecuted');
                    setTimeout(async () => {
                        await moveLastToFirst(finalData);
                    }, 2000);
                }
                if (pdfUrls && pdfUrls.length > 0 && JSON.stringify(pdfUrls) !== JSON.stringify(mappedData)) {
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error fetching bios and PDF URLs:', error);
            }
        };

        fetchBiosAndPdfUrls();


    }, []);


    const getPdfUrls = async () => {
        const response = await axiosInstance.get(`/api/form/patientuploadfile`);
        return response.data;
    };



    useEffect(() => {
        let intervalId;
        const fetchPdfUrls = async () => {
            const urls = await getPdfUrls();
            if (urls && urls.length > 0 && JSON.stringify(urls) !== JSON.stringify(pdfUrls)) {
                setPdfUrls(urls);
                clearInterval(intervalId);
                console.log(urls)
            }
        };

        fetchPdfUrls();
    }, []);

    const handlenavigate = (urls) => {

        setviewpdf(urls);
        navigate(`/viewpdf`)

    }


    const moveLastToFirst = async (items) => {
        console.log("display elements are", items)
        const updatedItems = Array.from(items);
        const lastItem = updatedItems.pop();
        updatedItems.unshift(lastItem);
        console.log("updated items are", updatedItems)
        setBios(updatedItems);

    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        console.log(result)
        const items = Array.from(bios);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log(items)
        setBios(items);
        updateOrderInBackend(items);
    };

    const updateOrderInBackend = async (updatedItems) => {
        // Exclude the last item from the update
        const itemsToUpdate = updatedItems.slice(0, -1);
        const token = localStorage.getItem('token');

        try {
            const response = await axiosInstance.post(
                '/api/form/updateOrder',
                {
                    reorderedItems: itemsToUpdate
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.status !== 200) {
                throw new Error('Failed to update order');
            }

            console.log('Order updated successfully');
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    const fetchDoctors = async () => {
        const response = await axiosInstance.get(`/api/form/search`, {
            params: {
                profession: "Doctor"
            },
        });

        setDoctors(response.data);

    };

    useEffect(() => {
        const fetchInitialData = async () => {
            await fetchDoctors();
        };
        fetchInitialData();
    }, []);

    const updateDoctorID = async (field, value, form_id) => {
        const token = localStorage.getItem('token');
        if (field == "doctor_ID") {
            try {
                const response = await axiosInstance.put(
                    `/api/form/updateDoctorID/${form_id}`,
                    {
                        doctor_ID: value,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );

                if (response.status !== 200) {
                    throw new Error('Failed to update order');
                }
                console.log(response.data)
                if (socketClient && socketClient.connected) {
                    socketClient.emit("send-patient", response.data);
                    console.log('Patient data sent via socket');
                } else {
                    console.error('Socket client is not connected');
                }

                console.log('Order updated successfully');
            } catch (error) {
                console.error('Error updating order:', error);
            }
        }
        else {
            try {
                const response = await axiosInstance.put(
                    `/api/form/updatedepartment/${form_id}`,
                    {
                        department: value,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );

                if (response.status !== 200) {
                    throw new Error('Failed to update order');
                }

                console.log('Order updated successfully');
            } catch (error) {
                console.error('Error updating order:', error);
            }
        }

    };

    const handleSelectChange = async (index, field, value, form_id) => {
        const updatedBios = [...bios];
        console.log(updatedBios)
        updatedBios[index][field] = value;
        setBios(updatedBios);
        await updateDoctorID(field, value, form_id);

    };

    return <div>
        <div className="container mx-auto px-4 py-8 mt-14">
            {qron && (<div className="fixed w-[100%] h-full top-0 left-0 bg-black bg-opacity-50 z-10 flex justify-center items-center ">
                <div className="flex w-[100%] h-[90%] justify-center items-center ">
                    <div className="bg-white  h-[100%] w-[75%]  z-20 rounded-lg relative overflow-y-auto overflow-x-hidden flex flex-col items-center p-2">
                        <Receptionistregistration setqron={setqron} />
                    </div>
                </div>
            </div>
            )}
            <div className=" mb-8">
                {/* <h1 className="text-3xl font-bold mb-4">Doctorqr</h1> */}

                <div style={{
                    marginTop: "-90px"
                }}>
                    <Qrcode />
                </div>
                {/* {imageBase64 && <img src={imageBase64} alt="doctorqr" className="w-32 h-32 mb-4" />} */}

            </div>

            {/* <div class="flex justify-center">
                <button onClick={() => setqron(true)} className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                    Add patient +
                </button>
            </div> */}
            <div className="flex justify-center" >
                <button onClick={() => setqron(true)} className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mt-2">
                    {t('add_patient')}+
                </button>
            </div>
            {/* <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border rounded py-2 px-4"
                    placeholder={t('search_by_patient_name')}
                    style={{ width: "700px" }}
                /> */}
            <div className="flex justify-center mt-4">
                <select
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)} // Update searchType when changed
                    className="border rounded-l py-2 px-4"
                    style={{ width: "700px" }}
                >
                    <option value="patient_name">{t('search_by_patient_name')}</option>
                    <option value="abha_id">{t('search_by_abha_id')}</option>
                    <option value="phone_number">{t('search_by_phone_number')}</option>
                </select>
            </div>
            <div className="flex justify-center">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border rounded-r py-2 px-4"
                    placeholder={t(`search_by_${searchType}`)} // Dynamically set placeholder
                    style={{ width: "700px" }}
                />
            </div>

            <div class="flex justify-center">
                {bios.length > 0 ? <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="bios" type="group">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ overflowX: 'auto', width: "700px" }}
                            >
                                <table className="w-full table-auto">
                                    <thead>
                                        <tr className="bg-gray-200">

                                            <th className="px-2 py-2">   {t('details')}</th>
                                            <th className="px-2 py-2">{t('files')}</th>
                                            <th className="px-4 py-2">{t('select')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bios.map((bio, index) => (
                                            <Draggable key={index} draggableId={`bio-${index}`} index={index}>
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="hover:bg-gray-100 transition duration-150 ease-in-out"
                                                    >
                                                        <td className="border px-4 py-2">
                                                            <p className="whitespace-nowrap"> {t('register.name')}: {bio?.bio?.name}</p>
                                                            <p className="whitespace-nowrap"> {t('register.father_name')}: {bio?.bio?.father_name}</p>
                                                            {/* <p className="whitespace-nowrap">  {bio?.bio?.abha}</p> */}

                                                            <p>  {t('history.age')}: {bio?.bio?.age}</p>
                                                            <p> {t('history.sex')}: {bio?.bio?.sex}</p>
                                                            <p className="whitespace-nowrap"> {t('register.phone')}: {bio?.bio?.phoneNumber}</p>
                                                            {bio?.doctor_ID ? (<p className="whitespace-nowrap"> {t('register.queue')}: {(() => {
                                                                // Filter bios by doctor_ID and find the index where _Id matches
                                                                const temp = bios
                                                                    ?.filter((bios) => bios.doctor_ID === bio?.doctor_ID)
                                                                const index = temp?.findIndex((bios) => bios?._id === bio?._id && bios.status === 'new' || bios.status === 'current'
                                                                );

                                                                return index !== -1 ? index + 1 : 'Index not found'; // Return index or a default message
                                                            })()}
                                                            </p>) : ""}
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <div className="flex flex-col space-y-2">
                                                                <p className="mt-1 text-sm text-green-600">
                                                                    {t('register.department')} :
                                                                </p>
                                                                <select
                                                                    value={bio?.department}
                                                                    onChange={(e) => handleSelectChange(index, 'department', e.target.value, bio._id)}
                                                                    className="border rounded px-2 py-1"
                                                                >
                                                                    <option value="">--{t('register.department')}--</option>
                                                                    {departments.map((dept) => (
                                                                        <option key={dept} value={dept}>
                                                                            {dept}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <p className="mt-1 text-sm text-green-600">
                                                                    {t('register.doctor')}:
                                                                </p>
                                                                <select
                                                                    value={bio?.doctor_ID}
                                                                    onChange={(e) => handleSelectChange(index, 'doctor_ID', e.target.value, bio._id)}
                                                                    className="border rounded px-2 py-1"
                                                                >
                                                                    <option value="">--{t('register.doctor')}--</option>
                                                                    {doctors.map((doctor) => (
                                                                        <option key={doctor._id} value={doctor._id}>
                                                                            {doctor.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            {/* Display Selected Doctor and Department */}
                                                            {/* 
                                                            {bio.doctor_ID && (
                                                                <p className="mt-1 text-sm text-green-600">
                                                                    Department: {bio.department}
                                                                </p>
                                                            )}
                                                            {bio.doctor_ID && (
                                                                <p className="mt-2 text-sm text-green-600">
                                                                    Doctor: {doctors.find(doc => doc._id === bio.doctor_ID)?.name || ''}
                                                                </p>
                                                            )} */}
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            {bio?.pdfUrls?.length > 0 && (
                                                                <div className="flex  space-x-4">
                                                                    {bio?.pdfUrls[0]?.files?.map((file, fileIndex) => (
                                                                        <div key={fileIndex} className="flex  items-center flex-col">
                                                                            <div className="flex flex-row flex-nowrap space-x-4" onClick={() => handlenavigate(file?.urls)}>
                                                                                <div style={{ width: "150px" }}>

                                                                                    <PdfToImage pdfUrl={file?.urls[0]?.url} />

                                                                                </div>

                                                                            </div>
                                                                            <p className="whitespace-nowrap">
                                                                                {new Date(file.urls[0]?.timestamp).toLocaleDateString()} {new Date(file.urls[0]?.timestamp).toLocaleTimeString()}

                                                                            </p>
                                                                            <p className="whitespace-nowrap">{file.urls[0]?.doctor_name}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}


                                                        </td>


                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                    :
                    ""
                }

            </div>


        </div >
    </div >;
}


export default Hospital;