import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
const PreviousForms = () => {
    const [previousForms, setPreviousForms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchForms = async () => {
            try {
                const response = await axiosInstance.get('/api/formcreation/forms');
                console.log(response.data);
                setPreviousForms(response.data);
            } catch (error) {
                console.error('Error fetching previous forms:', error);
            }
        };
        fetchForms();
    }, []);

    const handleFormSelect = (form) => {
        navigate(`/form-filler/${form}`)
    };

    return (
        <div className="mb-6  w-full">
            {previousForms.length === 0 ? (
                <p className="text-gray-600">No forms available.</p>
            ) : (
                <div className='flex p-2 w-max gap-2  no-scrollbar'>
                    {previousForms.map((form, index) => (
                        <div className='h-8 w-fit bg-blue-500 p-1 rounded-md hover:bg-blue-400 transition-all ease-in-out' key={index}>
                            <button
                                onClick={() => handleFormSelect(form?.formId)}
                                className="text-white"
                            >
                                {form.formType.title}
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PreviousForms;
