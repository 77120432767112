import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';

const Share = ({ formId, setNextFormData}) => {
    const [shareTab, setShareTab] = useState(false);
    const [docList, setDocList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sharedDoctor, setSharedDoctor] = useState(null);

    const toggleTab = () => {
        setShareTab(prev => !prev);
    };

    const currentDocEmail = localStorage.getItem('user_email');

    useEffect(() => {
        const fetchDoctorList = async () => {
            try {
                const response = await axiosInstance.get('/api/fetchdoctorlist');
                if (response.status === 200) {
                    const filteredList = response.data.list.filter(
                        doctor => doctor.email !== currentDocEmail
                    );
                    setDocList(filteredList);
                }
            } catch (error) {
                console.error("Error fetching doctor list:", error);
            }
        };
    
        fetchDoctorList();
    }, []);
    

    const shareWithDoctor = async (docId) => {
        setLoading(true);
        try {
            console.log("foid", formId)
            const response = await axiosInstance.post('/api/sharewithdoctor', { formId, docId });
            if (response.status === 200) {
                // setSharedDoctor(docId); 
                alert(response.data.msg);
                setNextFormData(false)
            } else {
                alert('Failed to share with doctor');
            }
        } catch (error) {
            console.error("Error sharing with doctor:", error);
            alert('Error sharing with doctor');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="absolute m-1 right-0">
            <button
                onClick={toggleTab}
                className={`${
                    shareTab ? 'bg-gray-200' : 'bg-white'
                } text-black font-sans p-1 rounded-lg border hover:bg-gray-200 transition-all duration-200`}
            >
                Share
            </button>
            {shareTab && (
                <div className="absolute right-0 top-10 p-4 w-[320px] bg-white rounded-md shadow-md border overflow-y-auto max-h-[400px]">
                    <h3 className="font-semibold mb-3 text-lg">Doctors List</h3>
                    {docList.length > 0 ? (
                        <ul className="space-y-2">
                            {docList.map((doctor, index) => (
                                <li key={index} className="flex items-center justify-between p-2 bg-gray-50 border rounded-lg shadow-sm">
                                    <div>
                                        <p className="font-medium">{doctor.name}</p>
                                        <p className="text-sm text-gray-600">{doctor.email}</p>
                                    </div>
                                    <button
                                        onClick={() => shareWithDoctor(doctor._id)}
                                        disabled={loading || sharedDoctor === doctor._id}
                                        className={`px-2 py-1 text-sm text-white rounded-md ${
                                            loading || sharedDoctor === doctor._id
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : 'bg-blue-500 hover:bg-blue-600 transition'
                                        }`}
                                    >
                                        {loading && sharedDoctor === doctor._id ? 'Sharing...' : 'Share'}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No doctors available</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Share;
