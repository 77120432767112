import React, { useEffect, useState } from 'react';
import downArrow from "../../../Images/down-arrow.svg";
import ActivityBoxes from './UI/ActivityBoxes';
import PatTable from './UI/Table';
import filter from "../../../Images/filter.svg";
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import handwritten from '../../../assets/handwriting.png';
import historys from '../../../assets/history.png';
import qrimage from '../../../assets/qr-code.png';
import searchimage from '../../../assets/search (2).png';
import { FaUpload } from "react-icons/fa6";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { MdOutlineReceipt } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

const Home = () => {

    const userName = localStorage.getItem("user_name");
    const [role, setRole] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setRole(decodedToken.role);
        }
    }, []);


    const isDoctor = role === 'Doctor';
    const isNonMedical = role === 'NonMedical';
    const isHospital = role === 'Hospital';

    return (
        <section>
            {/* {[userData].map((item, index) => (
                <h1 key={index} className='text-4xl pb-5 px-8'>Welcome, {item?.firstName || user?.displayName || 'User'}</h1>
            ))} */}

            <div className='flex items-center justify-between flex-col xl:flex-row mt-6 xl:mt-0 gap-4 xl:gap-0'>

                <div className='flex flex-col items-center xl:items-start'>
                    <h1 className='text-3xl'>Welcome, {userName && userName || 'User'}</h1>
                    <h2 className='text-[#667085] font-medium  pt-2'>Manage and track your patients</h2>
                </div>


                {/* <div className='text-[#344054] font-medium flex items-center gap-3'>
                    <p className='bg-[#dee5f9] text-blue-500 py-2 px-3 rounded-md cursor-pointer transition-all duration-300 ease-in-out'>Overview</p>
                    <p className='hover:bg-[#dee5f9] py-2 px-3 rounded-md cursor-pointer transition-all duration-300 ease-in-out'>Favorites</p>
                    <p className='hover:bg-[#dee5f9] py-2 px-3 rounded-md cursor-pointer transition-all duration-300 ease-in-out'>Help</p>
                </div> */}

                <div className="h-9 flex items-center z-20 py-1 flex-wrap">

                    <>
                        {isDoctor && (
                            <div className='flex gap-4 items-center '>
                                <Link to="/qr">
                                    <div className="flex justify-center items-center rounded-lg font-bold w-fit p-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
                                        <img
                                            src={qrimage}
                                            alt="QR Code"
                                            className="h-8 w-8"
                                        />
                                        <div className='text-black text-sm'>QR</div>
                                    </div>

                                </Link>

                                <Link to="/handwritten" >
                                    <div className="flex justify-center items-center rounded-lg font-bold w-fit p-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
                                        <img
                                            src={handwritten}
                                            alt="Handwritten"
                                            className="h-6 w-6"
                                        />
                                        <div className='text-black text-sm ml-1'>Editor</div>
                                    </div>
                                </Link>

                                <Link to="/history" >
                                    <div className="flex justify-center items-center rounded-lg font-bold w-fit p-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
                                        <img
                                            src={historys}
                                            alt="History"
                                            className="h-6 w-6"
                                        />
                                        <div className='text-black text-sm ml-1'>History</div>
                                    </div>
                                </Link>

                                <Link to='/search' >
                                    <div className="flex justify-center items-center rounded-lg font-bold w-fit p-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
                                        <img
                                            src={searchimage}
                                            alt="Search"
                                            className="h-6 w-6"
                                        />
                                        <div className='text-black text-sm ml-1'>Search</div>
                                    </div>
                                </Link>

                                <Link to='/reception' >
                                    <div className="flex justify-center items-center rounded-lg font-bold w-fit p-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl m-1">
                                        <div className='text-black text-sm ml-1'>Queue</div>
                                        <MdOutlineReceipt className="h-6 w-6 text-black" />
                                    </div>
                                </Link>

                                {/* <Link className="rounded-sm font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl flex items-center justify-center m-1">
                                    <p className="whitespace-nowrap"> {t('install')}</p>
                                </Link> */}
                            </div>
                        )}
                        {isNonMedical && (
                            <>
                                <Link to="/" className="flex justify-center items-center rounded-sm font-bold w-fit pl-2 pr-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                                    <img
                                        src={qrimage}
                                        alt="QR Code"
                                        className="h-8 w-8"
                                    />
                                    <div className='text-black text-sm'>QR</div>
                                </Link>
                                {/* <Link to="/scanner" className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <MdOutlineQrCodeScanner className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link> */}

                                <Link to='/search' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                                    <img
                                        src={searchimage}
                                        alt="Search"
                                        className="h-8 w-8 ml-4 my-1"
                                    />
                                </Link>
                                {/* <Link to='/upload' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 whitespace-nowrap text-xl">
                    <FaUpload className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link> */}

                                <Link to='/report-file' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 whitespace-nowrap text-xl">
                                    <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                                </Link>
                                {/* 
                                <Link className="rounded-lg font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                                    <p className="whitespace-nowrap"> {t('install')}</p>
                                </Link> */}
                            </>
                        )}

                        {
                            isHospital && (
                                <>
                                    <Link to="/" >
                                        <div className="flex justify-center items-center rounded-sm font-bold w-fit px-2 py-2 text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl ">
                                            <img
                                                src={qrimage}
                                                alt="QR Code"
                                                className="h-8 w-8"
                                            />
                                            <p className='text-black text-sm'>QR</p>
                                        </div>

                                    </Link>
                                    <Link to="/billing" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                                        <FaRegMoneyBillAlt className="h-8 w-8 ml-4 my-1 text-black" />
                                    </Link>
                                    <Link to="/hospitalreception" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                                        <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                                    </Link>
                                    {/* <Link className="rounded-lg font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                                        <p className="whitespace-nowrap"> {t('install')}</p>
                                    </Link> */}
                                </>

                            )
                        }
                    </>
                </div>
            </div>

            <div className='my-12'>
                <p className='text-3xl font-medium'>Frequently Used Paperwork</p>

                <div className='my-10 flex items-center gap-10'>
                    <div>
                        <p>XYZ Form</p>
                        <div className='mt-2 border border-[#AFB0B5] rounded-lg h-[212px] w-[318px]'>

                        </div>
                    </div>
                    <div>
                        <p>XYZ Form</p>
                        <div className='mt-2 border border-[#AFB0B5] rounded-lg h-[212px] w-[318px]'>
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-1'>
                    <p className='text-[#1D50EF] font-semibold text-sm'>All Forms</p>
                    <img src={downArrow} alt="down arrow" />
                </div>
            </div>

            <div className='my-12'>
                <p className='text-3xl font-medium'>My Activity</p>

                <div className='my-10 flex items-center gap-10'>
                    <ActivityBoxes heading={"Patients"} stat={"2,420"} />
                    <ActivityBoxes heading={"Appointments"} stat={"21"} />
                    <ActivityBoxes heading={"XYZ"} stat={"316"} />
                </div>
            </div>


            <div className=''>
                <div className='mb-4'>
                    <div className='rounded-lg border border-[#D0D5DD] shadow w-fit flex items-center py-[10px] px-4 gap-2'>
                        <img src={filter} alt="" />
                        <p className='text-sm font-semibold'>More Filters</p>
                    </div>
                </div>
                <PatTable
                    tableHead={["Patient", "Condition", "Status", "Appointment Date", "Note Summary"]}
                    patient={"Anil K"}
                    condition={"label"}
                    status={"Active"}
                    appointment={"02/11/2024"}
                    summary={"HPI 1 Case"}
                />
            </div>
        </section>
    )
}

export default Home
