// import React, { useEffect, useState } from 'react';
// const PDFJS = require("pdfjs-dist/webpack");

// const PdfToImage = ({ pdfUrl }) => {
//     const [imageUrl, setImageUrl] = useState(null);

//     useEffect(() => {
//         const loadPdf = async () => {
//             const loadingTask = PDFJS.getDocument(pdfUrl);
//             const pdf = await loadingTask.promise;
//             const page = await pdf.getPage(1);

//             const scale = 1.5;
//             const viewport = page.getViewport({ scale });

//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');
//             canvas.height = viewport.height;
//             canvas.width = viewport.width;

//             const renderContext = {
//                 canvasContext: context,
//                 viewport: viewport,
//             };

//             await page.render(renderContext).promise;

//             const imgDataUrl = canvas.toDataURL('image/png');
//             setImageUrl(imgDataUrl);
//         };

//         loadPdf();
//     }, [pdfUrl]);

//     return (
//         <span>
//             {imageUrl ? (

//                 <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px]" />

//             ) : (
//                 <img src={imageUrl} alt="Loading..." className="h-[180px] w-[180px]" />
//             )}
//         </span>
//     );
// };

// export default PdfToImage;

import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInterceptor';

const PDFJS = require("pdfjs-dist/webpack");

const PdfToImage = ({ pdfUrl, fileName }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [roles, setRoles] = useState(['doctor', 'patient', 'nurse']);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [message, setMessage] = useState('');

  const user_role = localStorage.getItem('user_role'); // Get the user role from local storage

  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = PDFJS.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);

      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const imgDataUrl = canvas.toDataURL('image/png');
      setImageUrl(imgDataUrl);
    };

    const timeoutId = setTimeout(() => {
      loadPdf();
    }, 500); // Run after 0.5 seconds

    return () => clearTimeout(timeoutId);
  }, [pdfUrl]);

  const handleRoleChange = (role) => {

    if (user_role === 'Hospital') {
      setSelectedRoles((prev) => {
        if (prev.includes(role)) {
          return prev.filter((r) => r !== role);
        } else {
          return [...prev, role];
        }
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.put('/files/authorize', {
        fileName,
        roles: selectedRoles,
      });
      setMessage(response.data.message || 'Roles updated successfully');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Error updating roles');
    }
  };

  return (
    <div className="pdf-to-image-container p-2 border rounded-lg shadow-md bg-white">
      <span className="flex justify-center ">
        {imageUrl ? (
          <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px] rounded-md shadow-lg" />
        ) : (
          <div className="text-gray-500"> <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px] rounded-md shadow-lg" /></div>
        )}
      </span>

      <div className="roles-container mt-2 ">
        <h4 className="text-sm font-semibold">Assign Roles</h4>
        {user_role === 'Hospital' ? (
          <div className="roles-list mt-2 max-h-40 overflow-y-auto border border-gray-300 rounded-md p-1">
            {roles.map((role) => (
              <div key={role} className="flex items-center mb-2">
                <label className="flex items-center text-sm">
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes(role)}
                    onChange={() => handleRoleChange(role)}
                    className="mr-2"
                  />
                  {role}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-sm text-red-600 whitespace-nowrap">Need permission</p>
        )}
        {user_role === 'Hospital' && (
          <button onClick={handleSubmit} className="mt-4 bg-blue-500 text-sm text-white py-2 px-4 rounded-md hover:bg-blue-600">
            Update Roles
          </button>
        )}
      </div>

      {message && <div className="mt-4 text-green-500">{message}</div>}
    </div>
  );
};

export default PdfToImage;

