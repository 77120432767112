import React, { useState, useEffect } from 'react';
import axiosInstance from "../axiosInterceptor";
import { useParams } from 'react-router-dom';

const Upload = () => {

    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null); // State for selected image
    const [showModal, setShowModal] = useState(false); // State for modal visibility

    const { patient_id } = useParams();

    useEffect(() => {
        fetchImages(); // Fetch images when the component mounts
    }, []);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get(`/api/form/patient-images/${patient_id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setImages(response.data); // Set images in state
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleview = (imageUrl) => {
        setSelectedImage(imageUrl); // Set the selected image URL
        setShowModal(true); // Show the modal
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    return (
        <div className="p-4">
            <div className="flex flex-wrap gap-2">
                {images?.length != 0 ? (images.map((image, index) => (
                    <img
                        key={index}
                        src={image.url}
                        alt={`Uploaded ${index}`}
                        className="w-32 h-32 object-cover  cursor-pointer"
                        onClick={() => handleview(image.url)} // Pass the image URL to handleview
                    />

                ))) : <h1> NO Uploads by the User</h1>}

            </div>

            {/* Modal for displaying full-screen image */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        <img
                            src={selectedImage}
                            alt="Full view"
                            className="max-w-screen-sm max-h-screen object-contain"
                        />
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-8 bg-white p-2 rounded-full text-black text-xl"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Upload;
