import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LogoutIcon from "../../Images/logout-icon.svg";
import NavLogo from "../../Images/NavLogo.svg";
import NavLogoSmall from "../../Images/logoSmall.svg";
import userIcon from "../../Images/userIcon.svg";
import avatar from "../../Images/Avatar.svg";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const SideNavigationBar = ({ MenuOptions, MenuBottomOptions, handleSidebar, handleSignOut, activeMenu, active }) => {
    const [collapseMenu, setCollapseMenu] = useState(false);
    const [role, setRole] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setRole(decodedToken.role);
            setIsLoggedIn(true);
        }
    }, []);

    const setTime = async () => {
        console.log("click");
        if (localStorage.getItem("user_name")) {
            localStorage.removeItem("user_login");
            localStorage.removeItem("user_name");
            localStorage.removeItem("token");
            setRole(null);
            setIsLoggedIn(false);
            // navigate("/login");
            navigate("/");
        }
    };

    const userName = localStorage.getItem("user_name")
    const userEmail = localStorage.getItem("user_email")
    console.log(userEmail)

    return (
        <div>
            {collapseMenu ?
                <div className='md:w-[20rem] w-full xl:relative absolute bg-white z-50 pt-4 pb-1 px-4 h-full flex flex-col justify-between transition-all duration-300 ease-in-out border-r-2 border-gray-[#EBECEE]'>
                    <div className='flex flex-col w-full'>
                        <div className='pb-6 pt-4 mx-auto'>
                            <img src={NavLogo} alt="EaseMyMeds" />
                        </div>

                        <div className='flex items-center gap-[6px] bg-[#F0F6FF] rounded-2xl w-fit px-2 py-[2px] mb-4'>
                            <p className='bg-[#12B76A] rounded-full h-2 w-2'></p>
                            <p className='text-[#4285F4] text-xs font-semibold'>{active}</p>
                        </div>

                        <ul className='list-none text-white cursor-pointer flex flex-col gap-1 w-full'>
                            {MenuOptions?.map((item, index) => (
                                <li onClick={() => handleSidebar(item.OptionName)} key={index} className={`hover:transition-all delay-75 ease-in-out rounded-lg flex items-center gap-3 
                                ${activeMenu === item.OptionName ? 'bg-[#dee5f9] border border-[#4285F4] py-2 px-4' : 'py-2 px-4 bg-gray-100'}`}>
                                    <img className='h-6 w-6' src={item.optionIcon} alt="icon" />
                                    <p className='text-[#4B4B4B] font-medium text-nowrap'>{item.OptionName}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <ul className='list-none text-white cursor-pointer flex flex-col gap-1 w-full'>
                            {MenuBottomOptions?.map((item, index) => (
                                <li key={index} onClick={() => handleSidebar(item.optionBottomName)} className={`flex gap-3 items-center rounded-xl hover:transition-all delay-75 ease-in-out ${activeMenu === item.optionBottomName ? 'bg-[#dee5f9] border border-[#4285F4] py-2 px-4' : 'py-2 px-4'}`}>
                                    <img className='h-6 w-6' src={item.optionBottomIcon} alt="icon" />
                                    <p className='text-[#4B4B4B] font-medium text-nowrap'>{item.optionBottomName}</p>
                                </li>
                            ))}
                        </ul>

                        {/* collapse menu icon  */}
                        <div className='flex items-center pl-4 gap-x-4 cursor-pointer' onClick={() => setCollapseMenu(!collapseMenu)}>
                            <svg width="67" height="75" viewBox="0 0 67 75" fill="none" xmlns="http://www.w3.org/2000/svg"
                                className='w-5 h-5 cursor-pointer rotate-180'>
                                <path d="M62.56 13.0914V61.8914" stroke="#4B4B4B" stroke-width="7.32" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.74233 4.63533C6.8222 3.11945 4 4.48707 4 6.93347V68.0495C4 70.4958 6.8222 71.8632 8.74233 70.3475L47.4491 39.7894C48.9341 38.6172 48.9341 36.3656 47.4491 35.1934L8.74233 4.63533Z" stroke="#4B4B4B" stroke-width="7.32" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className='text-[#4B4B4B] font-medium text-nowrap'>Minimize Tray</p>
                        </div>

                        <div className='pl-1 py-4 border-t border-[#475467] flex justify-between items-center flex-wrap'>
                            <div className='flex gap-3 items-center'>
                                <div className=''>
                                    <img className='h-10 w-10 rounded-full' src={avatar} alt="user" />
                                </div>
                                <div className='text-[#4B4B4B]'>
                                    <p className='text-sm font-semibold'>{ userName && userName || 'Manik Sejwal'}</p>
                                    <p className='text-sm'>{userEmail && userEmail || 'maniksejwal@gmail.com'}</p>
                                </div>
                            </div>
                            <div className='cursor-pointer pl-2' title='Log Out' onClick={setTime}>
                                <img src={LogoutIcon} alt="logout" />
                            </div>
                        </div>
                    </div>

                </div>
                :
                <div className='w-16 h-full p-2 pt-4 transition-all duration-400 ease-in-out flex flex-col justify-between border-r-2 border-gray-[#EBECEE]'>
                    <div className='flex flex-col w-full'>
                        <img className='pt-4 pb-6 mx-auto' src={NavLogoSmall} alt="EaseMyMed" />

                        <div className='flex items-center gap-[6px] bg-[#F0F6FF] rounded-full w-fit p-2 mb-4 mx-auto'>
                            <img src={userIcon} alt="user icon" />
                        </div>

                        <ul className='list-none text-white cursor-pointer flex flex-col gap-1 w-full'>
                            {MenuOptions?.map((item, index) => (
                                <li onClick={() => handleSidebar(item.OptionName)} key={index} className={`hover:transition-all duration-300 ease-in-out rounded-lg flex items-center gap-2 
                                ${activeMenu === item.OptionName ? 'bg-[#dee5f9] border border-[#4285F4] py-3 px-3' : 'py-3 px-3'}`}>
                                    <img className='h-6 w-6 mx-auto' src={item.optionIcon} alt="icon" />
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        {MenuBottomOptions?.map((item, index) => (
                            <li key={index} onClick={() => handleSidebar(item.optionBottomName)} className={`flex items-center py-3 gap-3 rounded-xl hover:transition-all delay-75 ease-in-out cursor-pointer ${activeMenu === item.optionBottomName ? 'bg-[#dee5f9] border border-[#4285F4] ' : ''}`}>
                                <img className='h-6 w-6 mx-auto' src={item.optionBottomIcon} alt="icon" />
                            </li>
                        ))}

                        <div className='flex justify-center pt-3 pb-4 px-auto'>
                            <svg onClick={() => setCollapseMenu(!collapseMenu)} width="67" height="75" viewBox="0 0 67 75" fill="none" xmlns="http://www.w3.org/2000/svg"
                                className='w-5 h-5 cursor-pointer'>
                                <path d="M62.56 13.0914V61.8914" stroke="#4B4B4B" stroke-width="7.32" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.74233 4.63533C6.8222 3.11945 4 4.48707 4 6.93347V68.0495C4 70.4958 6.8222 71.8632 8.74233 70.3475L47.4491 39.7894C48.9341 38.6172 48.9341 36.3656 47.4491 35.1934L8.74233 4.63533Z" stroke="#4B4B4B" stroke-width="7.32" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        <div className='pl-1 py-4 border-t border-[#475467] flex justify-between items-center flex-wrap'>
                            <div className='flex gap-3 items-center'>
                                <div className=''>
                                    <img className='h-10 w-10 rounded-full' src={avatar} alt="user" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

SideNavigationBar.propTypes = {
    MenuOptions: PropTypes.array.isRequired,
    MenuBottomOptions: PropTypes.array.isRequired,
    handleSidebar: PropTypes.func.isRequired,
    handleSignOut: PropTypes.func.isRequired,
    activeMenu: PropTypes.string.isRequired,
};

SideNavigationBar.defaultProps = {
    MenuOptions: [],
    MenuBottomOptions: [],
};

export default SideNavigationBar;
