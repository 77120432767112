import React, { useState, useEffect } from 'react';
import axiosInstance from "./axiosInterceptor";
import * as pdfjsLib from 'pdfjs-dist';
import { v4 as uuidv4 } from 'uuid';

const FormCreation = () => {
  const [formFields, setFormFields] = useState([]);
  const [currentField, setCurrentField] = useState({ type: 'text', label: '', desc: '', required: false });
  const [id, setId] = useState("");
  const [formType, setFormType] = useState({ title: '', desc: '' });
  const [attachedFiles, setAttachedFiles] = useState();
  const [showattachedFiles, setShowAttachedFiles] = useState();
  const [previousForms, setPreviousForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const docId = localStorage.getItem("user_login");
  const [creatorId, setcreatorId] = useState(docId)
  const [pdfPages, setPdfPages] = useState([]);

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axiosInstance.get('/api/formcreation/forms');
        console.log(response.data)
        setPreviousForms(response.data);
      } catch (error) {
        console.error('Error fetching previous forms:', error);
      }
    };
    fetchForms();
  }, []);

  const handleAddField = () => {
    if (currentField.label !== '') {
      setFormFields([...formFields, currentField]);
      setCurrentField({ type: 'text', label: '' });
    }
  };

  const handleFieldTypeChange = (e) => {
    setCurrentField({ ...currentField, type: e.target.value });
  };

  const handleLabelChange = (e) => {
    setCurrentField({ ...currentField, label: e.target.value });
  };
  const handleDescChange = (e) => {
    setCurrentField({ ...currentField, desc: e.target.value });
  };

  const handleRequiredChange = (e) => {
    setCurrentField({ ...currentField, required: true });
  };


  const handleFormTypeChange = (e) => {
    setFormType(prevFormType => ({
      ...prevFormType,
      [e.target.name]: e.target.value
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setAttachedFiles(file);
    let filePreview;
    console.log("ft",file.type)
    if (file.type === 'application/pdf') {
      const pages = await renderPdfAsImages(file);
      filePreview = { file, preview: pages };
    } else {
      const reader = new FileReader();
      filePreview = await new Promise((resolve) => {
        reader.onload = () => {
          resolve({ file, preview: [reader.result] });
        };
        reader.readAsDataURL(file);
      });
    }
    setShowAttachedFiles(filePreview);
  };


  const renderPdfAsImages = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        const pages = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;
          pages.push(canvas.toDataURL());
        }

        resolve(pages);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const [shareableLink, setShareableLink] = useState('');
  const [shareablebox, setShareablebox] = useState(false);
  const handleSubmit = async () => {
    const formId = uuidv4();
    const formData = new FormData();
    formData.append('creatorId', creatorId);
    formData.append('formType', JSON.stringify(formType));
    formData.append('formFields', JSON.stringify(formFields));
    formData.append('file', attachedFiles);
    formData.append('formId', formId);
    setShareableLink(formId)
    try {
      const response = await axiosInstance.post('/api/formcreation/forms', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Form successfully saved:', response.data);
    } catch (error) {
      console.error('Error saving form:', error);
    }
  };

  const handleFormSelect = async (form) => {
    setSelectedForm(form);
    setFormFields(form.formFields);
    setShareableLink(form.formId);
    setFormType({ title: form.formType.title, desc: form.formType.desc });

    const attachedFilePaths = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'}/${form.attachedFiles}`;

    console.log(attachedFilePaths)

    try {
      const response = await fetch(attachedFilePaths);
      const blob = await response.blob();
      const file = new File([blob], form.attachedFiles, { type: blob.type });

      let filePreview;
      console.log("ft",file.type)
      if (file.type === 'application/pdf') {
        const pages = await renderPdfAsImages(file);
        filePreview = { file, preview: pages };
      } else {
        const reader = new FileReader();
        filePreview = await new Promise((resolve) => {
          reader.onload = () => {
            resolve({ file, preview: [reader.result] });
          };
          reader.readAsDataURL(file);
        });
      }
      setShowAttachedFiles(filePreview);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };



  const handleShare = () => {
    setShareablebox(true)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/form-filler/${shareableLink}`);
    alert('Link copied to clipboard!');
  };

  const renderField = (field, index) => {
    switch (field.type) {
      case 'label':
        return (
          <div key={index} className="mb-4">
            <h1 className="block text-gray-700">
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </h1>
            <p className='text-gray-600 text-sm'>{field.desc}</p>
          </div>
        );
      case 'text':
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700">
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
            <p className='text-gray-600 text-sm'>{field.desc}</p>
            <input className="w-full p-2 border border-gray-300 rounded" type="text" required={field.required} />
          </div>
        );
      case 'checkbox':
        return (
          <div key={index} className="mb-4">
            <p className='text-gray-600 text-sm'>{field.desc}</p>
            <label className="block text-gray-700">
              <input className="mr-2" type="checkbox" required={field.required} />
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>

          </div>
        );
      case 'radio':
        return (
          <div key={index} className="mb-4">
            <p className='text-gray-600 text-sm'>{field.desc}</p>
            <label className="block text-gray-700">
              <input className="mr-2" type="radio" name="radioGroup" required={field.required} />
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
          </div>
        );
      case 'file':
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700">{field.label} {field.required && <span className="text-red-500">*</span>}</label>
            <p className='text-gray-600 text-sm'>{field.desc}</p>
            <input className="w-full p-2 border border-gray-300 rounded" type="file" required={field.required} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-2">
      <h2 className="text-xl font-semibold mb-2">Your Forms</h2>
      <div className="mb-6 overflow-scroll w-full">
        {previousForms.length === 0 ? (
          <p className="text-gray-600">No forms available.</p>
        ) : (
          <div className='flex p-2 w-max gap-2 overflow-scroll no-scrollbar'>
            {previousForms.map((form, index) => (
              <div className='h-8 w-fit bg-blue-500 p-1 rounded-md hover:bg-blue-400 transition-all ease-in-out' key={index}>
                <button
                  onClick={() => handleFormSelect(form)}
                  className="text-white"
                >
                  {form.formType.title}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <h2 className="text-xl font-semibold mb-2">Create Form</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Title:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          type="text"
          name='title'
          value={formType.title}
          onChange={handleFormTypeChange}
          placeholder="Enter field label"
        />
        <label className="block text-gray-700">Description:</label>
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          type="text"
          name='desc'
          value={formType.desc}
          onChange={handleFormTypeChange}
          placeholder="Enter field label"
        />
        {shareableLink === '' && <><label className="block text-gray-700">Attach Scanned Pages/Template:</label>
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            className="w-full p-2 border border-gray-300 rounded"
          /></>}
      </div>

      {shareableLink === '' && <><div className='flex justify-start'>
        <h2 className="mb-1 text-gray-500">Customization tool</h2>
      </div>
        <div className="mb-4">
          <label className="block text-gray-700">Field Label:</label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            value={currentField.label}
            onChange={handleLabelChange}
            placeholder="Enter field label"
          />
          <label className="block text-gray-700">Field Label Description:</label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            value={currentField.desc}
            onChange={handleDescChange}
            placeholder="Enter field label"
          />
          <div>
            <label className="block text-gray-700">Field Type:</label>
            <select
              className="w-full p-2 border border-gray-300 rounded"
              value={currentField.type}
              onChange={handleFieldTypeChange}
            >
              <option value="label">Label</option>
              <option value="text">Text</option>
              <option value="checkbox">Checkbox</option>
              <option value="radio">Radio Button</option>
              <option value="file">File Upload</option>
            </select>
            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={currentField.required}
                onChange={handleRequiredChange}
              />
              <label className="text-gray-700">Is this field mandatory?</label>
            </div>
          </div>
        </div>
        <button
          onClick={handleAddField}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Add Field
        </button>
      </>
      }
      <h2 className="text-xl font-semibold mb-4">Preview Form{shareableLink !== '' && <button className='ml-6 bg-blue-500 text-white rounded-lg p-2 text-sm hover:bg-blue-400' onClick={handleShare}>Share</button>}</h2>

      {showattachedFiles && (
        <div className="mb-4 w-full">
          <div className="mb-2 w-full grid justify-items-center">
            {showattachedFiles.file.type.startsWith('image/') ? (
              <img src={showattachedFiles.preview[0]} alt="File preview" className="" />
            ) : showattachedFiles.file.type === 'application/pdf' ? (
              <div className='w-screen grid justify-center'>
                {showattachedFiles.preview.map((page, pageIndex) => (
                  <img key={pageIndex} src={page} alt={`PDF page ${pageIndex + 1}`} className="mb-2" />
                ))}
              </div>
            ) : (
              <a href={showattachedFiles.preview[0]} download={showattachedFiles.file.name} className="text-blue-500">
                {showattachedFiles.file.name}
              </a>
            )}
          </div>
        </div>
      )}

      {formFields.length === 0 ? (
        <p className="text-gray-600">No fields added yet.</p>
      ) : (
        formFields.map((field, index) => renderField(field, index))
      )}

      {shareablebox && (
        <div className='fixed top-0 bg-gray-200 w-screen h-screen flex justify-center items-center'>
          <div className='bg-white p-6 rounded shadow-lg max-w-sm'>
            <h2 className='text-lg font-semibold mb-4'>Shareable Link</h2>
            <input
              type='text'
              readOnly
              value={`${window.location.origin}/form-filler/${shareableLink}`}
              className='w-full p-2 border border-gray-300 rounded mb-4'
            />
            <button
              onClick={handleCopyLink}
              className='w-full p-2 bg-blue-500 text-white rounded'
            >
              Copy Link
            </button>
            <button
              onClick={() => setShareablebox(false)}
              className='mt-2 w-full p-2 bg-gray-300 text-gray-800 rounded'
            >
              Close
            </button>
          </div>
        </div>
      )}

      {shareableLink !== '' ?
        <button
          onClick={handleShare}
          className="bg-green-500 text-white p-2 rounded mt-4"
        >
          Share Form
        </button> :
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white p-2 rounded mt-4"
        >
          Save Form
        </button>
      }
    </div>
  );
};

export default FormCreation;
