import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const PDFJS = require("pdfjs-dist/webpack");

const NewPatient = () => {
    const { doctorId } = useParams();
    const { t, i18n } = useTranslation();
    const [profession, setProfession] = useState('Doctor');
    const userRole = localStorage.getItem('user_role');
    const [users, setUsers] = useState([]);
    const [patient, setpatient] = useState(0);
    let patientId

    if (userRole == 'NonMedical')
        patientId = localStorage.getItem('user_login');
    else
        patientId = '';

    const fetchUsers = async () => {
        const response = await axiosInstance.get(`/api/form/search`, {
            params: {
                profession
            },
        });

        setUsers(response.data);

    };

    const fetchData = async (doctor) => {
        try {
            const response = await axiosInstance.get(
                `/api/form/bio/${doctor}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`, //
                    },
                }
            );
            setpatient(response.data?.length);
        } catch (error) {
            setpatient(0);
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        fetchUsers();
    }, []);

    const [formData, setFormData] = useState({
        bio: { name: '', age: '', sex: '', id: 'none', phoneNumber: '', address: '', paid: false },
        doctor_ID: '', patient_ID: '', patient_ID: patientId || '', uploads: { url: [], name: patientId },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            bio: {
                ...prevFormData.bio,
                [name]: value
            }
        }));
        console.log(formData)
    };

    const handleChangedoctor = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,

            [name]: value
        }));
        fetchData(value)
    };
    const handleSubmit = async (e) => {

        // e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (formData?.doctor_ID != '') {
                const response = await axiosInstance.post(`/api/form`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                console.log(response.data);
                // Handle success
                alert('Patient added successfully');
                setFormData({
                    bio: { name: '', age: '', sex: '', id: 'none', phoneNumber: '', address: '', paid: false },
                    doctor_ID: doctorId || '', patient_ID: patientId || '', uploads: { url: [], name: patientId },
                });
            }
            else {
                alert("doctor is not selected")
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error
        }
    };

    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'bxva8g23');

        const response = await fetch('https://api.cloudinary.com/v1_1/drz2uk3vm/image/upload', {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data.secure_url;
    };

    const saveToDatabase = async ({ url, name }) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axiosInstance.post(`/api/form/patient-upload/`, {
                url,
                name,
                patient_ID: patientId
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error saving URL to database:', error);
            throw error;
        }
    };

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const convertPdfToImages = async (file) => {
        const images = [];
        try {
            const data = await readFileData(file);
            console.log(data)
            const pdf = await PDFJS?.getDocument(data).promise;
            console.log("pdf", pdf)
            const canvas = document.createElement("canvas");

            for (let i = 0; i < pdf?.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const context = canvas.getContext("2d");

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                await page.render({ canvasContext: context, viewport: viewport }).promise;

                images.push(canvas.toDataURL());
            }

            canvas.remove();
        } catch (error) {
            console.error('Error converting PDF to images:', error);
        }

        return images;
    };

    const handleItChange = async (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        console.log("this is ", selectedFile);
        if (selectedFile) {
            try {
                const fileType = selectedFile.type;
                let cloudinaryUrl = '';

                if (fileType === 'application/pdf') {
                    console.log("Processing PDF...");

                    const images = await convertPdfToImages(selectedFile);
                    console.log('Converted PDF to images:', images);

                    const uploadedImages = await Promise.all(images.map(async (image, index) => {
                        const cloudinaryUrl = await uploadToCloudinary(image);
                        console.log(`Uploaded image ${index + 1} to Cloudinary:`, cloudinaryUrl);

                        // Save each Cloudinary URL to the database
                        const savedTemplate = await saveToDatabase({ url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` });

                        return { id: savedTemplate._id, url: cloudinaryUrl, name: `${selectedFile.name}-page-${index + 1}` };
                    }));

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        uploads: {
                            url: uploadedImages.map(image => image.url),
                            name: patientId
                        }
                    }));


                } else if (fileType === 'image/jpeg' || fileType === 'image/png') {
                    cloudinaryUrl = await uploadToCloudinary(selectedFile);
                    const savedImage = await saveToDatabase({ url: cloudinaryUrl, name: selectedFile.name });
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        uploads: {
                            url: [...prevFormData.uploads.url, cloudinaryUrl], // Append new URL to existing array
                            name: patientId // Ensure name is retained
                        }
                    }));

                } else {
                    console.error('Unsupported file type');
                    return;
                }
                // fetchImages();
            } catch (error) {
                console.error('Error handling file:', error);
            }
        }
    };
    const handleaddother = async (e) => {
        setFormData({
            bio: { name: '', age: '', sex: '', id: 'none', phoneNumber: '', address: '', paid: false },
            doctor_ID: doctorId || '', patient_ID: '', uploads: { url: [], name: patientId || '' },
        });
    };

    return (
        <>


            <div className="max-w-md mx-auto p-12 bg-white shadow-md rounded-md text-xl  relative overflow-y-scroll">
                <h2 className="text-xl font-semibold mb-4"> {t('register.new_patient_form')}</h2>

                <h2 className="text-xl font-semibold mb-4">{patient} {t('register.patient_already_in_queue')}</h2>
                {/* <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 md:h-10 lg:h-12 xl:h-14" onClick={handleaddother}>Add Family/Friends</button> */}
                <div className="mb-4">
                    <label htmlFor="doctor" className="block mb-1">{t('register.doctor')}:</label>

                    <select id="doctor" name="doctor_ID" value={formData.doctor_ID} onChange={handleChangedoctor} className="w-full border-2 border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400">
                        <option value="">-- Select Doctor --</option>
                        {users.map(user => (
                            <option key={user.id} value={user?._id}>
                                {user?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="patientName" className="block mb-1">{t('history.name')}:</label>
                        <input type="text" id="patientName" name="name" value={formData.bio.name} onChange={handleChange} className="w-full border-2  border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div clas sName="mb-4">
                        <label htmlFor="age" className="block mb-1">{t('history.age')}:</label>
                        <input type="number" id="age" name="age" value={formData.bio.age} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="gender" className="block mb-1">{t('history.sex')}:</label>
                        <select id="gender" name="sex" value={formData.bio.sex} onChange={handleChange} className="w-full border-2 border-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-blue-400">
                            <option value="">-- {t('history.sex')} --</option>
                            <option value="male">{t('history.male')}</option>
                            <option value="female">{t('history.female')}</option>
                            <option value="other">{t('history.other')}</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block mb-1">{t('register.phone')}:</label>
                        <input type="number" id="phoneNumber" name="phoneNumber" value={formData.bio.phoneNumber} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="address" className="block mb-1">{t('register.address')}:</label>
                        <input type="text" id="address" name="address" value={formData.bio.address} onChange={handleChange} className="w-full border-2  border-gray-900  rounded-md px-3 py-2 focus:outline-none focus:border-blue-400" />
                    </div>
                    <div className="flex flex-wrap gap-3">
                        {formData?.uploads?.url.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Uploaded ${index}`}
                                className="w-32 h-32 object-cover  cursor-pointer" // Pass the image URL to handleview
                            />
                        ))}
                    </div>
                    <div className='absolute right-8 mt-4'>
                        <label htmlFor="Uploadfile" className="bg-blue-500 text-white px-4 py-3 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 md:h-10 lg:h-12 xl:h-14" >
                            {t('register.add_file')}
                        </label>
                        <input type="file" id="Uploadfile" className="hidden" onChange={handleItChange} />
                    </div>
                    <div className='mt-2'>

                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 md:h-10 lg:h-12 xl:h-14">{t('register.submit')}</button>

                    </div>
                </form>
            </div>



        </>
    );
};

export default NewPatient;
