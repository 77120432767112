import React, { useState, useEffect } from 'react';
import HomeIcon from "../../../Images/home-menu.svg";
import UsersMenu from "../../../Images/users-menu.svg";
// import DashboardProfile from './DashboardProfile';
import SideNavigationBar from '../../../UI/Dashboard/SideNavigationBar';
import formIcon from "../../../Images/formIcon.svg";
import trendIcon from "../../../Images/trendIcon.svg";
import actionIcon from "../../../Images/actionIcon.svg";
import whatsappIcon from "../../../Images/whatsappIcon.svg";
import supportIcon from "../../../Images/supportIcon.svg";
import settingIcon from "../../../Images/settingIcon.svg";
import DocHome from "./Home";

const Main = () => {
    const [activeMenu, setActiveMenu] = useState('Home');

    const handleSidebar = (item) => {
        setActiveMenu(item)
    }

    const MenuOptions = [
        {
            OptionName: 'Home',
            optionIcon: HomeIcon,
            component: DocHome
        },

        {
            OptionName: 'Forms',
            optionIcon: formIcon,
            // component: DoctorReferrals
        },

        {
            OptionName: 'Trends',
            optionIcon: trendIcon,
            // component: DoctorReferrals
        },

        {
            OptionName: 'Actions',
            optionIcon: actionIcon,
            // component: DoctorReferrals
        },

        {
            OptionName: 'WhatsApp',
            optionIcon: whatsappIcon,
            // component: DoctorReferrals
        },

        {
            OptionName: 'Refer',
            optionIcon: UsersMenu,
            // component: DoctorReferrals
        },
    ];

    const MenuBottomOptions = [
        {
            optionBottomName: 'Support',
            optionBottomIcon: supportIcon,
            // component: DashboardProfile,
        },
        {
            optionBottomName: 'Settings',
            optionBottomIcon: settingIcon,
            // component: DashboardProfile,
        }
    ];

    const SelectedMenuComponent = MenuOptions.find(option => option.OptionName === activeMenu)?.component;

    const SelectedBottomComponents = MenuBottomOptions.find(option => option.optionBottomName === activeMenu)?.component;

    return (
        <section className='flex relative h-screen'>

            <SideNavigationBar
                MenuOptions={MenuOptions}
                MenuBottomOptions={MenuBottomOptions}
                handleSidebar={handleSidebar}
                // handleSignOut={handleSignOut}
                active={"Doctor"}
                activeMenu={activeMenu}
            />
            {/* right side */}
            <div className='w-full p-8 mx-auto overflow-y-auto'>
                {SelectedMenuComponent ? <SelectedMenuComponent /> : (SelectedBottomComponents ? <SelectedBottomComponents /> : null)}
            </div>

        </section>
    )
}

export default Main
