import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import download from '../assets/download.png';
import { Document, Page } from 'react-pdf';
const Report = ({ setviewpdf }) => {

    const { patient_id } = useParams();
    const navigate = useNavigate();
    const [pdfUrls, setPdfUrls] = useState([]);

    const handleView = (tabId) => {
        setviewpdf(tabId)
        navigate(`/viewpdf`);
    };

    function onDocumentLoadSuccess({ numPages }) {
        console.log(numPages)
    }
    const handleDownload = async (pdfUrl, doctor_name, patient_name) => {
        const today = new Date();
        const year = today.getFullYear().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        const hours = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');

        const filename = `${year}${month}${day}_${hours}${minutes}_${doctor_name}_${patient_name}.pdf`;
        console.log(pdfUrl)
        const secureUrl = pdfUrl?.replace(/^http:/, 'https:');
        console.log(secureUrl);
        try {
            const response = await fetch(secureUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("download", filename); // Set the filename for the download
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Release the object URL after the download
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    };

    useEffect(() => {
        const fetchPdfUrls = async () => {
            const response = await axiosInstance.get(`/api/form/pdf-urls/report/${patient_id}`);
            console.log(response.data)
            setPdfUrls(response.data);
        };

        fetchPdfUrls();
    }, []);

    return <div className="container mx-auto px-4 py-8">
        <div className="tab-pane">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Doctor</th>
                        <th>Patient</th>
                        <th>Preview</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {pdfUrls
                        ?.sort((a, b) => b.timestamp - a.timestamp)
                        ?.map((pdfUrl) => (
                            <tr key={pdfUrl.timestamp}>
                                <td> <p>{new Date(pdfUrl.timestamp).toLocaleDateString()}</p>
                                    <p className='whitespace-nowrap'> {new Date(pdfUrl.timestamp).toLocaleTimeString()}</p>
                                </td>

                                <td>{pdfUrl?.doctor_name}</td>
                                <td>{pdfUrl?.patient_id}</td>
                                <td onClick={() => handleView(pdfUrl?.url)
                                }>

                                    <Document
                                        file={pdfUrl?.url}

                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >

                                        <Page
                                            pageNumber={1}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={150}
                                            height={100}
                                        />


                                    </Document>
                                    {/* <img id='Template' src={`${pdfUrl?.url?.slice(0, -3)}png`} alt="LOGO"
                                        onClick={() => handleView(pdfUrl.url)}
                                    /> */}

                                </td>
                                <td >
                                    <img
                                        className="h-8 w-8 ml-4"
                                        src={download}
                                        onClick={() => handleDownload(pdfUrl.url, pdfUrl.doctor_name, pdfUrl.patient_id)}
                                    />


                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>

    </div >;
}

export default Report;