import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInterceptor';
import { useTranslation } from 'react-i18next';
const Register = ({ setLogin }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [blur, setBlur] = useState("noblur");
    const [loading, setLoading] = useState("noloader");
    const [message, setMessage] = useState("");
    const [role, setRole] = useState(null);
    const [dept, setDept] = useState([])

    const [user, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        dob: "",
        profession: "",
        subProfession: "",
        department: "",
        hospital: "",
        occupation: "",
        income: "",
        password: "",
        reEnterPassword: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setUser(prevUser => ({
            ...prevUser,
            [name]: value,
        }));
    };

    useEffect(() => {
        const docDeptApi = async () => {
            try {
                const response = await axiosInstance.get('/doc/dept');
                setDept(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        docDeptApi();
    }, []);

    const register = (e) => {
        e.preventDefault();

        setLoading("loader");
        setBlur("blur");
        const { name, phone, dob, profession, password, reEnterPassword, email } = user;
        if (name && phone && dob && profession && email && password && password === reEnterPassword) {
            if (isNaN(phone)) {
                setMessage("Invalid Number");
                setLoading("noloader");
                setBlur("noblur");
            } else {
                axiosInstance.post(`/api/form/register`, user).then((res) => {
                    setMessage(res.data.message);
                    setLoading("noloader");
                    setBlur("noblur");
                    setUser({
                        name: "",
                        email: "",
                        phone: "",
                        dob: "",
                        profession: "",
                        subProfession: "",
                        department: "",
                        hospital: "",
                        occupation: "",
                        income: "",
                        password: "",
                        reEnterPassword: "",
                    })
                }).catch((error) => {
                    console.error(error);
                    setLoading("noloader");
                    setBlur("noblur");
                });
            }
        } else {
            setMessage("**All fields are required");
            setLoading("noloader");
            setBlur("noblur");
        }
    };

    return (
        <>
            <div className={blur}>
                <div className={loading}></div>
            </div>
            <div>
                <div className='text-center mt-[84px] flex flex-col gap-2'>
                    <h1 className='text-PrimaryBlue text-4xl'>Welcome</h1>
                    <p className='text-lightGray font-medium'>Enter your details to get started</p>
                </div>

                <div className="mt-9 w-full">
                    {/* <h2>{t('register.register')}</h2> */}
                    <div className="flex flex-col gap-4">
                        <form className="flex flex-col gap-4">
                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Name</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                    type="text"
                                    name="name"
                                    value={user.name}
                                    placeholder={t('register.name')}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Mobile Number</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    name="phone"
                                    value={user.phone}
                                    placeholder={t('register.phone')}
                                    maxLength="10"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Date of Birth</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                    type="date"
                                    name="dob"
                                    value={user.dob}
                                    placeholder="DOB"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='flex flex-col gap-[4px]'>
                                <label className='text-sm'>Email ID</label>
                                <input
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                    type="text"
                                    name="email"
                                    value={user.email}
                                    placeholder={t('register.email')}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <label className='text-sm'>What's your profession</label>
                                    <select name="profession" className='rounded-xl border border-lightGray p-3 outline-blue-500' value={user.profession} onChange={handleChange}>
                                        <option value="" disabled>-- {t('register.profession')} --</option>
                                        <option value="Medical">{t('register.medical')}</option>
                                        <option value="Non-Medical">{t('register.nonmedical')}</option>
                                    </select>
                                </div>

                                {user.profession === "Medical" && (
                                    <select name="subProfession" className='rounded-xl border border-lightGray p-3 outline-blue-500' value={user.subProfession} onChange={handleChange}>
                                        <option value="" disabled>-- {t('register.sub_profession')} --</option>
                                        <option value="Doctor">{t('register.doctor')}</option>
                                        <option value="Hospital">{t('register.hospital')}</option>
                                    </select>
                                )}
                                {user.profession === "Non-Medical" && (
                                    <>
                                        <input
                                            className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                            type="text"
                                            name="occupation"
                                            value={user.occupation}
                                            placeholder={t('register.occupation')}
                                            onChange={handleChange}
                                        />
                                        <input
                                            className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                            type="text"
                                            name="income"
                                            value={user.income}
                                            placeholder={t('register.income')}
                                            onChange={handleChange}
                                        />
                                    </>
                                )}
                                {user.profession === "Medical" && user.subProfession === "Doctor" && (
                                    <>
                                        {/* <input
                                            className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                            type="text"
                                            name="department"
                                            value={user.department}
                                            placeholder={t('register.department')}
                                            onChange={handleChange}
                                        /> */}
                                        <select name="department" className='rounded-xl border border-lightGray p-3 outline-blue-500' value={user.department} onChange={handleChange}>
                                            <option value="" disabled>-- {t('register.department')} --</option>
                                            {dept.map((item, index) => (
                                                <option key={index} value={item.departments}>
                                                    {item.departments}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            className='rounded-xl border border-lightGray p-3 outline-blue-500'
                                            type="text"
                                            name="hospital"
                                            value={user.hospital}
                                            placeholder={t('register.hospital')}
                                            onChange={handleChange}
                                        />
                                    </>
                                )}
                            </div>

                            <div>
                                <label className='text-sm'>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500 w-full'
                                    value={user.password}
                                    placeholder={t('register.password')}
                                    onChange={handleChange}
                                />
                            </div>

                            <div>
                                <label className='text-sm'>Confirm Password</label>
                                <input
                                    type="password"
                                    name="reEnterPassword"
                                    className='rounded-xl border border-lightGray p-3 outline-blue-500 w-full'
                                    value={user.reEnterPassword}
                                    placeholder={t('register.re_password')}
                                    onChange={handleChange}
                                />

                            </div>
                            <p className="messaged text-sm">{message}</p>

                            <button className="button px-auto py-[10px] rounded-xl bg-PrimaryBlueBg text-white hover:scale-[1.02] transition-transform duration-300 ease-in-out text-center w-full" onClick={register}>
                                {t('register.register')}
                            </button>
                        </form>

                    </div>

                    <p className=' text-lightGray font-semibold text-center mt-2'>Already have an account? <span className='text-[#DB4437]  cursor-pointer' onClick={() => setLogin(true)}>Login Here</span></p>
                    {/* <div style={{ color: "black" }}>{t('register.or')}</div>
                    <div style={{ color: "black" }}>
                        {t('register.back')} <span className="xyz" onClick={() => navigate("/login")}>{t('register.home')}</span>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Register;

