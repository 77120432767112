import React from 'react';
import AIIMS from "../Images/AIIMS.png";
import IITJ from "../Images/IITJ.png"
import Laptop from "../Images/laptop.svg";

const Companies = () => {
  return (
    <section className='px-mobile xl:px-xlarge lg:px-large 2xl:max-w-[1440px] 2xl:mx-auto flex flex-col items-center gap-12 md:pt-24 md:pb-20 py-8'>
      <img className='pb-8 md:hidden' src={Laptop} alt="" />
      <h1 className='text-SubGray font-medium text-center'>Join the revolution in immutability and changeability</h1>
      <div className='flex justify-center items-center lg:gap-24 flex-wrap gap-8'>
        <img className='h-[170px] w-44' src={AIIMS} alt="AIIMS Jodhpur" />
        <img className='h-[172px] w-44' src={IITJ} alt="IIT Jodhpur" />
      </div>
    </section>
  )
}

export default Companies
