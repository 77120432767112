import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInterceptor';
import { FaPlus, FaMinus, FaSave } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Qrcode from './Qrcode';
import Receptionistregistration from "./Component/Receptionistregistration";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PdfToImage from './PdfToImage';
import AttachFile from "./Component/AttachFile"

const Receptionist = ({ formData, setviewpdf, forms, setForms, setFormData, setfileId }) => {
  const { t, i18n } = useTranslation();
  const [bios, setBios] = useState([]);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const [qron, setqron] = useState(false);
  const [newBio, setNewBio] = useState({
    bio: {
      name: '',
      age: '',
      sex: '',
      id: 'none',
      paid: false,
    },
    doctor_ID: localStorage.getItem('user_login') || ''
  });

  const [imageBase64, setImageBase64] = useState('');
  const [pdfUrls, setPdfUrls] = useState([]);
  const [selectedTab, setSelectedTab] = useState('queue');
  const [editingIndex, setEditingIndex] = useState(null);

  const [doctor_ID, setDoctor_ID] = useState()


  useEffect(() => {
    const storedImage = localStorage.getItem('doctorqr');
    if (storedImage) {
      setImageBase64(storedImage);
    }
  }, []);

  useEffect(() => {
    const doctorID = localStorage.getItem('user_login');
    setDoctor_ID(doctorID)

    let intervalId;

    const fetchBiosAndPdfUrls = async () => {
      try {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('user_role');
        let biosResponse;
        if (role === 'Hospital') {
          biosResponse = await axiosInstance.get('/api/form/reception', {
            headers: { 'Authorization': `Bearer ${token}` },
            params: {
              doctor_ID: doctorID
            }
          });
        }
        else {
          biosResponse = await axiosInstance.get(
            `/api/form/bio/${localStorage.getItem("user_login")}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`, //
              },
            }
          );
        }

        const bios = biosResponse.data;
        setdata(bios);

        const pdfUrls = await getPdfUrls();

        console.log("bios", bios)
        const mappedData = bios?.map(bio => {
          // if (!bio?.patient_ID) {
          //   return {
          //     ...bio,
          //     pdfUrls: []
          //   };
          // }

          const matchingPdfUrls = pdfUrls?.filter(pdf => {
            if (bio?.patient_ID != "" && pdf?.patient_uploadId != " ") {
              console.log("bios",)
              return pdf?.patient_uploadId == bio?.patient_ID;
            }
            if (pdf?.patient_id != "")
              return pdf?.patient_id == bio?._id;
          });

          if (matchingPdfUrls?.length > 0) {
            console.log("mamdm", matchingPdfUrls)
          }

          const pdfUrlsForBio = matchingPdfUrls[0]?.files?.map(file => ({
            files: [
              {
                urls: file?.urls?.map(url => ({
                  url: url?.url,
                  pageNames: url?.pageNames || []
                }))
              }
            ],
            doctor: file?.urls[0]?.doctor || " ",
            timestamp: file?.urls[0]?.timestamp || " "
          }));
          return {
            ...bio,
            pdfUrls: matchingPdfUrls || [],
          };
        });
        console.log("mapped data", mappedData)
        const emptyTokenPdfUrls = pdfUrls.filter(pdf => pdf?.patient_id == " " && pdf?.patient_uploadId == " ");
        console.log(emptyTokenPdfUrls)

        const unmatchedEntries = emptyTokenPdfUrls.length > 0
          ? emptyTokenPdfUrls.map(pdf => ({
            patient_ID: "unknown",
            pdfUrls: [pdf] || []
          }))
          : [];



        const finalData = [...mappedData, ...unmatchedEntries];
        console.log(finalData);
        setBios(finalData);

        console.log("bios", bios);

        if (pdfUrls && pdfUrls.length > 0 && JSON.stringify(pdfUrls) !== JSON.stringify(mappedData)) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching bios and PDF URLs:', error);
      }
    };

    fetchBiosAndPdfUrls()

  }, []);


  const getPdfUrls = async () => {
    const response = await axiosInstance.get(`/api/form/patientuploadfile`);
    return response.data;
  };



  useEffect(() => {
    let intervalId;
    const fetchPdfUrls = async () => {
      const urls = await getPdfUrls();
      if (urls && urls.length > 0 && JSON.stringify(urls) !== JSON.stringify(pdfUrls)) {
        setPdfUrls(urls);
        clearInterval(intervalId);
        console.log(urls)
      }
    };

    fetchPdfUrls();
  }, []);

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
  };

  const handleView = (tabId) => {
    const secureUrl = tabId.replace(/^http:/, 'https:');
    localStorage.setItem("pdf", secureUrl);
    navigate(`/viewpdf`);
  };
  // const handleDownload = async (pdfUrl) => {
  //   const today = new Date();
  //   const year = today.getFullYear().toString().padStart(2, '0');
  //   const month = (today.getMonth() + 1).toString().padStart(2, '0');
  //   const day = today.getDate().toString().padStart(2, '0');
  //   const hours = today.getHours().toString().padStart(2, '0');
  //   const minutes = today.getMinutes().toString().padStart(2, '0');

  //   const filename = `${year}${month}${day}_${hours}${minutes}_${localStorage.getItem("user_name")}_${formData?.bio?.name}.pdf`;
  //   console.log(pdfUrl)
  //   const secureUrl = pdfUrl?.replace(/^http:/, 'https:');
  //   console.log(secureUrl);
  //   try {
  //     const response = await fetch(secureUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(new Blob([blob]));

  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute("download", filename); // Set the filename for the download
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();

  //     // Release the object URL after the download
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Failed to download PDF:', error);
  //   }
  // };
  // const handleAddRow = () => {
  //   setBios([...bios, newBio]);
  //   setEditingIndex(bios.length);
  //   setNewBio({
  //     bio: {
  //       name: '',
  //       age: '',
  //       sex: '',
  //       id: 'none',
  //       paid: false,
  //     },
  //     doctor_ID: localStorage.getItem('user_login') || ''
  //   });
  // };


  const handlenavigate = async (id, urls, ids) => {
    // Find the item in data by id
    const foundItem = data.find(item => item._id === id);

    if (foundItem) {

      if (window.handlenavigatefile) {
        await window.handlenavigatefile(urls, ids);
      }

      foundItem.status = "new";
      setFormData(foundItem)

      console.log(foundItem)
      const currentIndex = forms.indexOf(formData);
      console.log("Current index:", currentIndex);
      console.log("Forms array:", formData);

      if (currentIndex === -1) {
        const newForms = [...forms, foundItem];
        setForms(newForms);
        console.log("Updated forms with new item added at the end:", newForms);
        navigate(`/handwritten`);
        return;
      }

      const newForms = [
        ...forms.slice(0, currentIndex),
        foundItem,
        ...forms.slice(currentIndex)
      ];

      setForms(newForms);

      navigate(`/handwritten`);


      // Update the forms state
      console.log("Updated forms:", newForms);
    }
    else {
      setviewpdf(urls);
      navigate(`/viewpdf`);
    }
    // Optionally, navigate to another page or do something else
  };

  const [previousForms, setPreviousForms] = useState([]);
  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axiosInstance.get('/api/formcreation/forms');
        console.log(response.data)
        setPreviousForms(response.data);
      } catch (error) {
        console.error('Error fetching previous forms:', error);
      }
    };
    fetchForms();
  }, []);

  const handleSaveRow = async (index) => {
    // Add your save logic here
    setEditingIndex(null); // Clear the editing state
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post(`/api/form`, bios[index], {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }


  const handleRemoveRow = async (index, id) => {
    const updatedBios = [...bios];
    updatedBios.splice(index, 1);
    setBios(updatedBios);

    try {
      const response = await axiosInstance.delete('/api/form/delete', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Adjust token retrieval as needed
        },
        data: { id }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }

  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedBios = [...bios];
    updatedBios[index].bio[name] = value;
    setBios(updatedBios);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    // console.log(result)

    // If the last row was dragged, do not reorder
    if (result.destination.index === bios.length - 1) return;
    if (result.source.index === bios.length - 1) return;

    const items = Array.from(bios);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // console.log(items)
    setBios(items);
    updateOrderInBackend(items);
  };

  const updateOrderInBackend = async (updatedItems) => {
    // Exclude the last item from the update
    const itemsToUpdate = updatedItems.slice(0, -1);
    const token = localStorage.getItem('token');

    try {
      const response = await axiosInstance.post(
        '/api/form/updateOrder',
        {
          reorderedItems: itemsToUpdate
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to update order');
      }

      // console.log('Order updated successfully');
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div className="container mx-auto px-4 py-8 mt-14">
      {qron && (<div className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-10 flex justify-center items-center ">
        <div className="flex w-full h-[90%] justify-center items-center ">
          <div className="bg-white  h-[100%] z-20 rounded-lg relative overflow-y-auto overflow-x-hidden flex flex-col items-center p-2">
            <div className="relative top-0 left-40 cursor-pointer" >
              <img className="h-6 w-6 z-10" src="/cross2.png" alt="Close" onClick={() => setqron(false)} />
            </div> <Receptionistregistration />
          </div>
        </div>
      </div>
      )}
      <div className="mb-8">
        {/* <h1 className="text-3xl font-bold mb-4">Doctorqr</h1> */}

        <div style={{
          marginTop: "-30px"
        }}>
          <Qrcode />
        </div>
        {/* {imageBase64 && <img src={imageBase64} alt="doctorqr" className="w-32 h-32 mb-4" />} */}

      </div>

      <div class="flex justify-end">
        <button onClick={() => setqron(true)} className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded mb-2">
          {t('add_patient')} +
        </button>
      </div>
      <div class="flex justify-center w-full">


        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="bios" type="group">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ overflowX: 'auto' }}
                className='w-full'
              >
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">

                      <th className="px-2 py-2"> {t('details')}</th>
                      {/* <th className="px-2 py-2">Age</th>
                      <th className="px-2 py-2">Sex</th>
                      <th className="px-2 py-2">ID</th>*/}
                      {/* <th className="px-2 py-2">Uploads</th> */}
                      <th className="px-2 py-2"> {t('files')}</th>


                      {/* <th className="px-2 py-2">Paid</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {bios.map((bio, index) => (
                      <Draggable key={index} draggableId={`bio-${index}`} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${bio.status === "consulted" ? "bg-green-50 hover:bg-green-100" : bio.status === "current" ? "bg-blue-100 hover:bg-blue-200" : "bg-yellow-100 hover:bg-yellow-200"} hover:bg-white transition duration-150 ease-in-out`}

                          >
                            {/* <td className="border px-4 py-2">
                              {editingIndex === index ? (
                                <FaSave
                                  className="text-blue-500 cursor-pointer"
                                  onClick={() => handleSaveRow(index)}
                                />
                              ) : (
                                <>
                                  <FaPlus
                                    className="text-green-500 cursor-pointer"
                                    onClick={handleAddRow}
                                  />
                                  <FaMinus
                                    className="text-red-500 cursor-pointer"
                                    onClick={() => handleRemoveRow(index, bio?._id)}
                                  />
                                </>
                              )}
                            </td> */}
                            <td className="border px-4 py-2">
                              {bio?.bio ? (<><p className="whitespace-nowrap"> {t('register.name')}:{bio?.bio?.name}</p>
                                <p> {t('history.age')}: {bio?.bio?.age}</p>
                                <p>{t('history.sex')}: {bio?.bio?.sex}</p>
                                <p className="whitespace-nowrap"> {t('register.phone')}: {bio?.bio?.phoneNumber}</p>
                              </>) : ""}
                              {/* <input
                                type="text"
                                name="name"
                                value={bio?.bio?.name}
                                onChange={(e) => handleInputChange(e, index)}
                                className="w-full px-2 py-1 border rounded"
                                disabled={editingIndex !== index && editingIndex !== null}
                              /> */}
                            </td>
                            {/* <td className="border px-4 py-2">
                              {bio?.bio?.age}
                            </td>
                            <td className="border px-4 py-2">
                              {bio?.bio?.sex}
                            </td>
                            <td className="border px-4 py-2">
                              {bio?.bio?.id}
                              
                          </td> */}
                            {/* <td className="border px-4 py-2">
                              <button
                                className="download-btn"
                                onClick={() => navigate(`/doctorsidefile/${bio?.patient_ID}`)}
                              >
                                View
                              </button>
                            </td> */}
                            <td className="border px-4 py-2">
                              {/* {bio?.pdfUrls[0] ?
                                <Document
                                  file={bio?.pdfUrls[0] ? bio?.pdfUrls[0] : ""}
                                  onClick={() => handlenavigate(bio?.pdfUrls)
                                  }
                                >
                                  <Page
                                    pageNumber={1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    width={150}
                                    height={100}
                                    canvasRef={(canvas) => {
                                      if (canvas) {
                                        // Convert the canvas to an image
                                        console.log(canvas)
                                        const img = canvas.toDataURL('image/png');
                                        canvas.style.display = 'none';
                                        const imageElement = document.createElement('img');
                                        imageElement.src = img;
                                        imageElement.width = 150;
                                        imageElement.height = 100;
                                        canvas.parentElement.appendChild(imageElement);
                                      }
                                    }}
                                  />
                                  <p className='whitespace-nowrap'>{new Date(bio?.timestamp).toLocaleDateString()} {new Date(bio?.timestamp).toLocaleTimeString()}</p>

                                </Document>
                                : "NO FILES EXIST"} */}
                              {/* {bio?.pdfUrls[0] ? (
                                <div onClick={() => handlenavigate(bio?.pdfUrls)}>
                                  <PdfToImage pdfUrl={bio.pdfUrls[0]} />
                                  <p className='whitespace-nowrap'>{new Date(bio?.timestamp).toLocaleDateString()} {new Date(bio?.timestamp).toLocaleTimeString()}</p>
                                </div>
                              ) : (
                                ''
                              )} */}

                              {bio?.pdfUrls?.length > 0 && (
                                <div className="flex  space-x-4 overflow-x-scroll">
                                  {bio?.pdfUrls[0]?.files?.map((file, fileIndex) => (
                                    <div key={fileIndex} className="flex  items-center flex-col">
                                      <div className="flex flex-row flex-nowrap space-x-4" onClick={() => handlenavigate(bio?._id, file.urls, file?._id)}>
                                        <div style={{ width: "150px" }}>

                                          <PdfToImage pdfUrl={file?.urls[0]?.url} />

                                        </div>

                                      </div>
                                      <p className="whitespace-nowrap">
                                        {new Date(file.urls[0]?.timestamp).toLocaleDateString()} {new Date(file.urls[0]?.timestamp).toLocaleTimeString()}

                                      </p>
                                      <p className="whitespace-nowrap">{file.urls[0]?.doctor_name}</p>
                                      <button
                                        onClick={() => { setIsPopupOpen(true); setfileId(file?._id) }}
                                        className="mt-2 bg-blue-500 text-white py-1 px-3 rounded"
                                      >
                                        Attach File
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}


                            </td>

                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>


              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {
        isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
              <div className="relative top-0 left-96 cursor-pointer" onClick={() => {
                setIsPopupOpen(false)
              }}>
                <img className="h-10 w-10" src="/cross2.png" alt="X" />
              </div>
              <h2 className="text-lg font-bold mb-4">Select a Form to Attach</h2>

              <AttachFile />
              {/* <button
              onClick={() => setIsPopupOpen(false)}
              className="mt-4 bg-red-500 text-white py-1 px-3 rounded"
            >
              Close
            </button> */}
            </div>
          </div>
        )
      }


    </div >
  );
};

export default Receptionist;